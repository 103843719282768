import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { musicServices, UploadedMusicData } from "../../services/music.services";
import { useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";
import AudioPlayerContainer from "../music/AudioPlayer.container";
import { NoMusicItem } from "../music/NoMusicItem";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { eventTracker } from "../../helpers/eventTracker";
import { NO_MUSIC_TRACK, NO_MUSIC_TRACK_ID } from "../constants";
import { PostIdeaContext } from "../context/postIdeaContext";
import { MelodieGenreGroupOrPurposeAPI, MusicSelection } from "../music/musicSlice";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { MelodieGenreGroupOrPurposeRow } from "../music/melodieGenreGroupOrPurposeRow";
import { MelodieTracksFullScreenDialog } from "../music/melodieTracksFullScreenDialog";
import { UploadMusicItem } from "../music/UploadMusicItem";
import { UploadedMusicItem } from "../music/UploadedMusicItem";
import { UploadedTracksFullScreenDialog } from "../music/uploadedTracksFullScreenDialog";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";
import { AudioDrawer } from "../ui/audioDrawer";
import { musicItemHelper } from "../music/musicItemHelper";
import { clearAndStopAudioPlayerTrack } from "../ui/uiSlice";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { getSelectedTrackIdFromPostJson } from "../postIdea/postIdeaHelper";
import { EditMelodieMusicHeader } from "./editMelodieMusicHeader";
import { Divider, Typography } from "@mui/material";
import { FullScreenModalInnerWrap } from "./fullScreenModalInnerWrap";

export interface EditMelodieMusicTabContentProps extends WithFullScreenDialogProps
{
  handleCloseAfterApplyingMusic: () => void;
}

export function EditMelodieMusicTabContent( props: EditMelodieMusicTabContentProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;

  const [genreGroups, setGenreGroups] = useState<MelodieGenreGroupOrPurposeAPI[]>( [] );
  const [purposes, setPurposes] = useState<MelodieGenreGroupOrPurposeAPI[]>( [] );

  const [selectedGenreGroupOrPurpose, setSelectedGenreGroupOrPurpose] = useState<MelodieGenreGroupOrPurposeAPI>();

  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );

  const [loading, setLoading] = useState( false );
  const [pendingSelection, setPendingSelection] = useState<MusicSelection | undefined>( undefined );

  const dispatch = useDispatch();
  // TODO use uploaded music track to show the currently selected track in the fullscreen uploaded music dialog
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedMusicTrack, setUploadedMusicTrack] = useState<UploadedMusicData>();
  const [showUploadedMusicDialog, setShowUploadedMusicDialog] = useState( false );
  const userUploadedMusicFeatureOn = apptimizeVariables.shouldAllowUserUploadedMusic();

  const commonSxProps = { mx: 5, my: 5, cursor: "pointer" };

  useEffect( () =>
  {
    eventTracker.logEditPostMusicPickerShown( postIdea );
    fetchMelodieGenreGroupsAndPurposes();
  }, [] );

  async function fetchMelodieGenreGroupsAndPurposes()
  {
    setLoading( true )
    try
    {
      const melodieGenreGroupsAndPurposesAPI = await musicServices.getMelodieGenresGroupsAndPurposes();
      setGenreGroups( melodieGenreGroupsAndPurposesAPI.genre_groups )
      setPurposes( melodieGenreGroupsAndPurposesAPI.purposes )
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "Hmm, something failed on our end. Please try again." ) ) );
    }
    setLoading( false );
  }

  function logMusicChanged( toTrack: string )
  {
    const currentTrack = pendingSelection ? pendingSelection.id : selectedTrackId;
    const fromTrack = isEmpty( currentTrack ) ? NO_MUSIC_TRACK : currentTrack;
    eventTracker.logEditPostMusicChanged( postIdea, fromTrack, toTrack );
  }

  function handleNoMusicSelected()
  {
    logMusicChanged( NO_MUSIC_TRACK );
    setPendingSelection( musicItemHelper.getNoMusicTrack() );
  }

  function handleGenreGroupOrPurposeSelected( genreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI )
  {
    eventTracker.logEditPostMusicMelodieGenreGroupOrPurposeClicked( postIdea, genreGroupOrPurpose );
    setSelectedGenreGroupOrPurpose( genreGroupOrPurpose );
    setPendingSelection( undefined );
  }

  function closeMelodieTracksFullScreenDialog()
  {
    setSelectedGenreGroupOrPurpose( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function closeUploadedTracksFullScreenDialog()
  {
    setShowUploadedMusicDialog( false );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function handleShowUploadedMusicDialog()
  {
    setShowUploadedMusicDialog( true );
    setPendingSelection( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function handleUploadMusicSelected()
  {
    setPendingSelection( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  return (
    <>
      <EditMelodieMusicHeader title="Add music" handleClose={props.handleClose}/>
      <AudioPlayerContainer/>
      <FullScreenModalInnerWrap>
        <NoMusicItem postIdea={postIdea}
                     selected={musicItemHelper.isTrackIdSelected( NO_MUSIC_TRACK_ID, selectedTrackId, pendingSelection )}
                     handleTrackSelected={handleNoMusicSelected}
                     iconSize={40}
                     sx={commonSxProps}/>
        {userUploadedMusicFeatureOn && <UploadMusicItem setUploadedMusicTrack={setUploadedMusicTrack}
                                                        setLoading={setLoading}
                                                        onUploadMusicSelected={handleUploadMusicSelected}
                                                        sx={commonSxProps}/>}

        <Typography variant="h5" sx={{ m: 5, fontWeight: 600 }}>Browse music</Typography>

        {userUploadedMusicFeatureOn && <UploadedMusicItem handleOpenUploadedMusicDialog={handleShowUploadedMusicDialog} sx={commonSxProps}/>}


        {map( genreGroups, ( genre ) => <MelodieGenreGroupOrPurposeRow key={genre.id} melodieGenreGroupOrPurpose={genre}
                                                                       sx={commonSxProps}
                                                                       onClick={handleGenreGroupOrPurposeSelected}/> )}
        <Divider/>
        {map( purposes, ( purpose ) => <MelodieGenreGroupOrPurposeRow key={purpose.id} melodieGenreGroupOrPurpose={purpose}
                                                                      sx={commonSxProps}
                                                                      onClick={handleGenreGroupOrPurposeSelected}/> )}
        {selectedGenreGroupOrPurpose && <MelodieTracksFullScreenDialog manageOpenStateExternally={true}
                                                                       externalStateOpen={!!selectedGenreGroupOrPurpose}
                                                                       hideTopBar={true}
                                                                       handleClose={closeMelodieTracksFullScreenDialog}
                                                                       melodieGenreGroupOrPurpose={selectedGenreGroupOrPurpose}
                                                                       handleCloseAfterApplyingMusic={props.handleCloseAfterApplyingMusic}/>}

        {userUploadedMusicFeatureOn && showUploadedMusicDialog && <UploadedTracksFullScreenDialog manageOpenStateExternally={true}
                                                                                                  externalStateOpen={showUploadedMusicDialog}
                                                                                                  hideTopBar={true}
                                                                                                  handleClose={closeUploadedTracksFullScreenDialog}/>}

      </FullScreenModalInnerWrap>

      <AudioDrawer pendingSelection={pendingSelection} setPendingSelection={setPendingSelection}
                   closeAfterApplying={props.handleCloseAfterApplyingMusic}/>
      {loading && <ProgressOverlay/>}
    </>
  )
}

export const EditMelodieMusicFullScreenDialog = withFullScreenDialog( EditMelodieMusicTabContent )
