import { Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PostEditingDrawerContainer } from "../editing/edtingDrawer/postEditingDrawerContainer";
import { getColorPaletteSlug, getFontSetSlug, hasBrandCardInPostJson, hasUseBrandInBrandSlideInPostJson } from "../postIdea/postIdeaHelper";
import { BrandSettingsFullScreenDialog } from "../editing/brandSettingsFullScreenDialog";
import { postIdeaServices } from "../../services/postIdeaServices";
import { getBrandStyleColorPalette, getBrandStyleFontSet } from "../business/businessSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { eventTracker } from "../../helpers/eventTracker";
import { DetailedToggle } from "./detailedToggle";
import { DrawerTitle } from "../typography/DrawerTitle";
import { AlkaiCaption } from "../typography/alkaiCaption";
import { PostIdeaContext } from "../context/postIdeaContext";
import { setRequestEditorPauseAudioTimestamp } from "../ui/uiSlice";

export interface BrandSettingsDrawerProps
{
  handleDrawerClose: () => void;
}

export function BrandSettingsDrawer( props: BrandSettingsDrawerProps )
{
  const dispatch = useDispatch();
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const hasBrandCardEnabledInPostJson = hasBrandCardInPostJson( postIdea );
  const hasAppliedBrandingToBrandSlideInPostJson = hasUseBrandInBrandSlideInPostJson( postIdea );
  const [brandCardEnabled, setBrandCardEnabled] = React.useState<boolean>( hasBrandCardEnabledInPostJson );
  const [applyBrandColorsFontsEnabled, setBrandColorsFontsEnabled] = React.useState<boolean>( hasAppliedBrandingToBrandSlideInPostJson );
  let hasPendingChanges = useRef<boolean>( false );
  const [showBrandSettingsFullScreenDialog, setShowBrandSettingsFullScreenDialog] = useState<boolean>( false );
  const brandStyleFontSet = useSelector( ( state: RootState ) => getBrandStyleFontSet( state ) );
  const brandStyleColorPalette = useSelector( ( state: RootState ) => getBrandStyleColorPalette( state ) );

  const postIdeaColorPaletteSlug = getColorPaletteSlug( postIdea );
  const postIdeaFontSetSlug = getFontSetSlug( postIdea );

  const doesPostIdeaFontMatchBrandStyleFont = brandStyleFontSet?.slug === postIdeaFontSetSlug;
  const doesPostIdeaColorMatchBrandStyleColor = brandStyleColorPalette?.slug === postIdeaColorPaletteSlug;
  const brandColorDefinedButNotUsedInPost = !!brandStyleColorPalette && !doesPostIdeaColorMatchBrandStyleColor;
  const brandFontDefinedButNotUsedInPost = !!brandStyleFontSet && !doesPostIdeaFontMatchBrandStyleFont;
  const isAnyDefinedBrandElementNotUsedInPost = brandColorDefinedButNotUsedInPost || brandFontDefinedButNotUsedInPost

  const hasNeverSetUpBrandStyleFontOrColors = !brandStyleFontSet && !brandStyleColorPalette;
  const toggleApplyBrandKitDisabled = hasNeverSetUpBrandStyleFontOrColors || !brandCardEnabled;

  const [showUndo, setShowUndo] = useState<boolean>( false );
  const undoPostIdeaBrandCardEnabled = useRef<boolean>( hasBrandCardEnabledInPostJson );
  const undoPostIdeaUseBrandInBrandSlide = useRef<boolean>( applyBrandColorsFontsEnabled );
  const undoPostIdeaHasBrandStylesApplied = useRef<boolean>( doesPostIdeaFontMatchBrandStyleFont || doesPostIdeaColorMatchBrandStyleColor );
  const undoPostIdeaColorPaletteSlug = useRef<string>( postIdeaColorPaletteSlug );
  const undoPostIdeaFontSetSlug = useRef<string>( postIdeaColorPaletteSlug );

  const shouldShowBrandingNotAppliedSection = !hasNeverSetUpBrandStyleFontOrColors && isAnyDefinedBrandElementNotUsedInPost && !showUndo

  useEffect( () =>
  {
    setBrandCardEnabled( hasBrandCardEnabledInPostJson );
  }, [hasBrandCardEnabledInPostJson] );

  useEffect( () =>
  {
    setBrandColorsFontsEnabled( hasAppliedBrandingToBrandSlideInPostJson );
  }, [hasAppliedBrandingToBrandSlideInPostJson] );

  function handleUseBrandCardForThisPostClicked( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = event.target.checked;
    if ( newValue != null )
    {
      hasPendingChanges.current = true;
      setBrandCardEnabled( newValue );
      applyBrandToPost( newValue, applyBrandColorsFontsEnabled );
      eventTracker.logPostIdeaBrandSlideToggled( postIdea, newValue );
    }
  }

  async function applyBrandToPost( enabled: boolean, use_brand_in_brand_slide: boolean )
  {
    await postIdeaServices.applyBrandStyle( postIdea.id, { enabled, use_brand_in_brand_slide } );
  }

  function handleEditBrandSettings()
  {
    eventTracker.logPostIdeaEditBrandSettingsClicked( postIdea );
    setShowBrandSettingsFullScreenDialog( true );
    dispatch( setRequestEditorPauseAudioTimestamp( new Date().getTime() ) );
  }

  function closeBrandSettingsFullScreenDialog()
  {
    setShowBrandSettingsFullScreenDialog( false );
  }

  function handleApplyBrandColorsFontsClicked( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = !event.target.checked;
    if ( newValue != null )
    {
      hasPendingChanges.current = true;
      setBrandColorsFontsEnabled( newValue );
      applyBrandToPost( brandCardEnabled, newValue );
      eventTracker.logPostIdeaBrandKitForBrandSlideToggled( postIdea, newValue )
    }
  }

  async function handleApplyBrandKitToPostIdea()
  {
    setShowUndo( true );
    undoPostIdeaBrandCardEnabled.current = brandCardEnabled;
    undoPostIdeaUseBrandInBrandSlide.current = !applyBrandColorsFontsEnabled;
    undoPostIdeaHasBrandStylesApplied.current = doesPostIdeaFontMatchBrandStyleFont && doesPostIdeaColorMatchBrandStyleColor;
    undoPostIdeaFontSetSlug.current = postIdeaFontSetSlug;
    undoPostIdeaColorPaletteSlug.current = postIdeaColorPaletteSlug;
    return await postIdeaServices.applyBrandStyle( postIdea.id,
      { enabled: true, use_brand_in_brand_slide: true, use_brand_in_post_idea: true } );
  }

  async function handleApplyUndoValues()
  {
    setShowUndo( false );
    return await postIdeaServices.applyBrandStyle( postIdea.id, {
      enabled: undoPostIdeaBrandCardEnabled.current,
      use_brand_in_brand_slide: undoPostIdeaUseBrandInBrandSlide.current,
      use_brand_in_post_idea: undoPostIdeaHasBrandStylesApplied.current,
      font_set_slug: undoPostIdeaFontSetSlug.current,
      color_palette_slug: undoPostIdeaColorPaletteSlug.current
    } );
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto" }}>
      <DrawerTitle>Brand settings</DrawerTitle>
      <Stack spacing={10} sx={{ alignItems: "center", pb: 5, margin: "auto" }} maxWidth="400px" textAlign="left">
        {shouldShowBrandingNotAppliedSection && <Stack sx={{ margin: "auto", py: 6 }} textAlign="center" alignItems="center">
          <AlkaiCaption sx={{ mb: 4 }}>This post content doesn't use your colors & fonts.<br/>Do you want to change that?</AlkaiCaption>
          <Button variant="contained" sx={{ width: "250px" }}
                  onClick={handleApplyBrandKitToPostIdea}>Apply</Button>
        </Stack>}
        {showUndo && <Button variant="contained" sx={{ width: "250px" }}
                             onClick={handleApplyUndoValues}>Undo</Button>}


        <Stack direction="column" alignItems="center" justifyContent="space-between" maxWidth="400px">
          <DetailedToggle disabled={false}
                          checked={brandCardEnabled}
                          title="Add Brand Card to end of post"
                          description="Include your brand card on this post."
                          disabledHint=""
                          onChange={handleUseBrandCardForThisPostClicked}/>
          {!hasNeverSetUpBrandStyleFontOrColors && <DetailedToggle disabled={toggleApplyBrandKitDisabled}
                                                                   checked={!applyBrandColorsFontsEnabled}
                                                                   title="Use post style in Brand Card"
                                                                   description="Brand card will use post-specific styles."
                                                                   disabledHint=""
                                                                   onChange={handleApplyBrandColorsFontsClicked}/>}
        </Stack>


        <Stack>
          {hasNeverSetUpBrandStyleFontOrColors && <Typography variant="subtitle1">Set up more of your brand kit</Typography>}
          <Button variant="contained"
                  sx={{ width: "250px" }}
                  disabled={!brandCardEnabled}
                  onClick={handleEditBrandSettings}>
            Edit brand kit
          </Button>
        </Stack>

        {showBrandSettingsFullScreenDialog && <BrandSettingsFullScreenDialog
          manageOpenStateExternally={true}
          externalStateOpen={showBrandSettingsFullScreenDialog}
          hideTopBar={true}
          handleClose={closeBrandSettingsFullScreenDialog}
        />}
      </Stack>
    </PostEditingDrawerContainer>

  )
}
