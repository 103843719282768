import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialNetworkAccountType, YOUTUBE } from "../../services/business.services";
import * as React from "react";
import { useContext, useEffect } from "react";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { SocialMediaAccountRow } from "../userSettings/SocialMediaAccountRow";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { ShareDestinationToggle } from "./ShareDestinationToggle";

import { YOUTUBE_PRIVACY_PUBLIC, YoutubePrivacyOption } from "../constants";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import { getPreviousYoutubePost, YoutubeSharingSettings } from "../sharing/youtubeSharingSettings";
import { YoutubeSettingsSection } from "./youtubeSettingsSection";
import { ConnectOAuthSocialNetworkButton } from "../buttons/connectOAuthSocialNetworkButton";
import { EDIT_POST_PAGE_SOURCE } from "../../helpers/trackingConstants";
import { YouTube } from "@mui/icons-material";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { useDispatch } from "react-redux";

export interface YoutubeDirectShareRowProps
{
  postIdea: PostIdeaDataAPI;
  previousShareBatch?: ShareBatchAPI;
  showProgress?: ( title: string, accountType: SocialNetworkAccountType ) => void;
  hideProgress?: () => void;
  updateSharingSettings?: ( settings: YoutubeSharingSettings ) => void;
}

export function YoutubeDirectShareRow( props: YoutubeDirectShareRowProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const socialNetworkAccounts = socialNetworkAccountsContext.socialNetworkAccounts;
  const connectedYoutube = socialNetworkAccountHelper.getConnectedYoutube( socialNetworkAccounts );
  const [showYoutubeSharingSettings, setShowYoutubeSharingSettings] = React.useState( false );
  const [youtubeSelected, setYoutubeSelected] = React.useState( false );
  const previousYoutubePost = getPreviousYoutubePost( props.previousShareBatch, connectedYoutube );
  const [videoTitle, setVideoTitle] = React.useState( previousYoutubePost?.youtube_settings?.video_title || props.postIdea.headline );
  const [privacySetting, setPrivacySetting] = React.useState<YoutubePrivacyOption>(
    previousYoutubePost?.youtube_settings?.privacy || YOUTUBE_PRIVACY_PUBLIC );
  const readyToShare = !!connectedYoutube && !socialNetworkAccountHelper.needsRefresh( connectedYoutube );
  const dispatch = useDispatch();

  useEffect( () =>
  {
    const sharingSettings: YoutubeSharingSettings = {
      youtubeSettings: {
        privacy: privacySetting,
        video_title: videoTitle,
      },
      selectedToShare: youtubeSelected,
      readyToShare: readyToShare,
    }

    if ( props.updateSharingSettings )
    {
      props.updateSharingSettings( sharingSettings );
    }
  }, [privacySetting, videoTitle, youtubeSelected, readyToShare] )

  function youtubeStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setYoutubeSelected( checked );

    if ( checked )
    {
      setShowYoutubeSharingSettings( true );
    }
    else
    {
      setShowYoutubeSharingSettings( false );
    }
  }

  function hideLoadingOverlay()
  {
    if ( !!props.hideProgress )
    {
      props.hideProgress();
    }
  }

  function getYoutubeSwitch()
  {

    function handleYoutubeConnected( accountType: SocialNetworkAccountType, switchedAccount?: boolean )
    {
      hideLoadingOverlay();
      setYoutubeSelected( true );
      setShowYoutubeSharingSettings( true );

      if ( switchedAccount )
      {
        dispatch( setAlertMessage( warningAlert( "You are now connected to a new YouTube account." ) ) );
      }
    }

    function handleYoutubeConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleYoutubeConnectionStarted()
    {
      if ( !!props.showProgress )
      {
        props.showProgress( "Connecting to YouTube...", YOUTUBE );
      }
    }

    function handleRefreshYoutubeAccountComplete( accountType: SocialNetworkAccountType, succeeded: boolean, switchedAccount?: boolean )
    {
      if ( succeeded )
      {
        if ( switchedAccount )
        {
          dispatch( setAlertMessage( warningAlert( "You are now connected to a new YouTube account." ) ) );
        }
      }
    }

    if ( !!connectedYoutube )
    {
      if ( socialNetworkAccountHelper.needsRefresh( connectedYoutube ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedYoutube}
                                      onRefreshAccountComplete={handleRefreshYoutubeAccountComplete}
                                      source={EDIT_POST_PAGE_SOURCE}/>;
      }

      const accountIcon = <SocialAccountProfileIcon profileImageUrl={connectedYoutube.profile_image_url} accountType={YOUTUBE}/>;

      return <ShareDestinationToggle icon={accountIcon}
                                     label={connectedYoutube.name}
                                     checked={youtubeSelected}
                                     onChange={youtubeStatusChanged}/>;
    }

    return <ConnectOAuthSocialNetworkButton accountType={YOUTUBE}
                                            source={EDIT_POST_PAGE_SOURCE}
                                            iconType={YouTube}
                                            handleConnectionSucceeded={handleYoutubeConnected}
                                            handleConnectionFailed={handleYoutubeConnectionFailed}
                                            handleConnectionStarted={handleYoutubeConnectionStarted}/>;
  }

  function handleYoutubePrivacyChanged( privacy: YoutubePrivacyOption )
  {
    setPrivacySetting( privacy );
  }

  function handleYoutubeVideoTitleChanged( title: string )
  {
    setVideoTitle( title );
  }

  function renderYoutubeSettingsSection()
  {
    return <YoutubeSettingsSection
      privacy={privacySetting}
      videoTitle={videoTitle}
      handlePrivacyChanged={handleYoutubePrivacyChanged}
      handleTitleChanged={handleYoutubeVideoTitleChanged}/>;
  }

  return (<>
    {getYoutubeSwitch()}
    {showYoutubeSharingSettings && renderYoutubeSettingsSection()}
  </>)

}