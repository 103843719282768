import * as React from 'react';
import { useEffect } from 'react';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { businessServices } from "../../services/business.services";

function UploadedTrackList( props: WithFullScreenDialogProps )
{
  useEffect( () =>
  {
    // setLoading( true )
    businessServices.fetchUploadedMusic().then(
      ( response ) =>
      {
        console.log( response )
      }
    ).finally(
      () =>
      {
        // setLoading( false );
      }
    );
  }, [] );

  return (
    <FullPageMobileContainer>
      <Box sx={{ p: 8 }}>
        <Stack spacing={10} width="100%" sx={{ pb: 20 }}>
          <Box>
            <IconButton
              aria-label="close"
              onClick={props.handleClose}>
              <CloseIcon fontSize="inherit"/>
            </IconButton>
          </Box>
          Genres and Purposes tracks go here
        </Stack>
      </Box>
    </FullPageMobileContainer>
  );
}

export const UploadedTracksFullScreenDialog = withFullScreenDialog( UploadedTrackList )
