import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { musicServices } from "../../services/music.services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isEmpty, isEqual, map, sortBy } from "lodash";
import { EpidemicMusicItem } from "../music/EpidemicMusicItem";
import { postIdeaServices } from "../../services/postIdeaServices";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import AudioPlayerContainer from "../music/AudioPlayer.container";
import { NoMusicItem } from "../music/NoMusicItem";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { eventTracker } from "../../helpers/eventTracker";
import { NO_MUSIC_TRACK, TRACK_TYPE_EPIDEMIC_SOUND } from "../constants";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { clearAndStopAudioPlayerTrack } from "../ui/uiSlice";
import { PostIdeaContext } from "../context/postIdeaContext";
import { TrackAPI } from "../music/musicSlice";
import { BeatSyncIconImage } from "../music/BeatSyncIconImage";
import LoadingButton from '@mui/lab/LoadingButton';

interface PendingSelection
{
  music_url: string;
  epidemic_track_id: string;
  expires: string;
  start_time_in_seconds: number;
}

export function EditMusicTabContent()
{
  const postIdea = useContext( PostIdeaContext ).postIdea;

  const layoutSupportsBeatSync = !!postIdea.configuration.config_bundle.beat_sync_rules;
  const unsortedTracks = useSelector( ( state: RootState ) => state.music.tracks );
  const tracks = layoutSupportsBeatSync ? sortTracks( unsortedTracks ) : unsortedTracks;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );
  const musicData = JSON.parse( post.global_settings_data ).musicData;
  const selectedTrackId = musicData?.id ? musicData.id : "";
  const [loading, setLoading] = useState( false );
  const [applyingMusic, setApplyingMusic] = useState( false );
  const [pendingSelection, setPendingSelection] = useState<PendingSelection | undefined>( undefined );
  const pendingSelectionTrackId = pendingSelection ? pendingSelection.epidemic_track_id : "";
  const pendingSelectionDifferentThanInitial = pendingSelection && pendingSelectionTrackId !== selectedTrackId;
  const dispatch = useDispatch();

  useEffect( () =>
  {
    setLoading( true )
    musicServices.getEpidemicSoundCollection().finally(
      () =>
      {
        setLoading( false );
      }
    );
  }, [] );

  function sortTracks( unsortedTracks: TrackAPI[] ): TrackAPI[]
  {
    const sortedTracks = sortBy( unsortedTracks, [function ( track )
                                                  {
                                                    return !track.is_beat_sync_recommended;
                                                  }] );
    return sortedTracks;
  }

  function logMusicChanged( toTrack: string )
  {
    const currentTrack = pendingSelection ? pendingSelection.epidemic_track_id : selectedTrackId;
    const fromTrack = isEmpty( currentTrack ) ? NO_MUSIC_TRACK : currentTrack;
    eventTracker.logEditPostMusicChanged( postIdea, fromTrack, toTrack );
  }

  function logMusicApplied( toTrack: string )
  {
    const fromTrack = isEmpty( selectedTrackId ) ? NO_MUSIC_TRACK : selectedTrackId;
    eventTracker.logEditPostMusicApplied( postIdea, fromTrack, toTrack );
  }

  function logMusicCanceled( pendingTrack: string )
  {
    const backToTrack = isEmpty( selectedTrackId ) ? NO_MUSIC_TRACK : selectedTrackId;
    eventTracker.logEditPostMusicCanceled( postIdea, backToTrack, pendingTrack );
  }

  function handleNoMusicSelected()
  {
    logMusicChanged( NO_MUSIC_TRACK );
    const pendingSelectionObject: PendingSelection = {
      music_url: "",
      epidemic_track_id: "",
      expires: "",
      start_time_in_seconds: 0,
    }
    setPendingSelection( pendingSelectionObject );
  }

  function handleMusicSelected( url: string, epidemic_id: string, expires: string, startTimeInSeconds: number )
  {
    logMusicChanged( epidemic_id );
    const pendingSelectionObject: PendingSelection = {
      music_url: url,
      epidemic_track_id: epidemic_id,
      expires: expires,
      start_time_in_seconds: startTimeInSeconds,
    }
    setPendingSelection( pendingSelectionObject );
  }

  async function handleApplyPendingSelection()
  {
    if ( pendingSelection )
    {
      setApplyingMusic( true );
      logMusicApplied( pendingSelection.epidemic_track_id );
      await postIdeaServices.replaceMusic( postIdea.id,
        pendingSelection.music_url,
        pendingSelection.epidemic_track_id,
        TRACK_TYPE_EPIDEMIC_SOUND,
        pendingSelection.expires,
        pendingSelection.start_time_in_seconds )
      setApplyingMusic( false );
    }
  }

  function handleCancelPendingSelection()
  {
    if ( pendingSelection )
    {
      logMusicCanceled( pendingSelection.epidemic_track_id );
    }
    dispatch( clearAndStopAudioPlayerTrack() );
    setPendingSelection( undefined );
  }

  function isTrackIdSelected( trackId: string )
  {
    if ( pendingSelection )
    {
      return pendingSelection?.epidemic_track_id === trackId;
    }
    else
    {
      return selectedTrackId === trackId || (isEmpty( selectedTrackId ) && isEmpty( trackId ));
    }
  }

  return (
    <>
      {layoutSupportsBeatSync &&
       <Stack direction="row" gap={4} alignItems={"center"} justifyContent={"center"}>
         <BeatSyncIconImage/><Typography sx={{ color: "darkViolet" }} noWrap>Recommended for this layout</Typography>
       </Stack>
      }
      <AudioPlayerContainer/>
      <NoMusicItem postIdea={postIdea} selected={isTrackIdSelected( "" )}
                   handleTrackSelected={handleNoMusicSelected}/>
      {
        map( tracks, ( track ) =>
        {
          const selected = isTrackIdSelected( track.epidemic_id )
          return <EpidemicMusicItem handleTrackSelected={handleMusicSelected}
                                    key={track.epidemic_id}
                                    track={track}
                                    selected={selected}
                                    current_layout_supports_beat_sync={layoutSupportsBeatSync}/>
        } )
      }
      <Drawer
        anchor={"bottom"}
        open={pendingSelectionDifferentThanInitial}
        variant="persistent">
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2, mb: 6 }}>
          <Typography variant={"h6"}>Apply new music selection?</Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 6 }}>
            <LoadingButton loading={applyingMusic}
                           startIcon={<HighlightOffIcon/>}
                           variant="contained" color="negative"
                           onClick={handleCancelPendingSelection}>Cancel</LoadingButton>
            <LoadingButton loading={applyingMusic}
                           startIcon={<CheckCircleOutlineIcon/>}
                           variant="contained"
                           color="positive"
                           onClick={handleApplyPendingSelection}>Apply</LoadingButton>
          </Box>
        </Box>
      </Drawer>
      {loading && <ProgressOverlay/>}
    </>
  )
}
