import {
  businessServices,
  FACEBOOK_INSTAGRAM,
  FACEBOOK_PAGE,
  SocialNetworkAccountAPI,
  SocialNetworkAccountType
} from "../../services/business.services";
import React, { useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { SwitchSocialNetworkAccountButton } from "./SwitchSocialNetworkAccountButton";
import { ConfirmFacebookPageAccount } from "../confirmSocialNetworkAccount/confirmFacebookPageAccount";
import { ConfirmInstagramAccount } from "../confirmSocialNetworkAccount/confirmInstagramAccount";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { RefreshFacebookButton } from "../buttons/refreshFacebookButton";
import { size } from "lodash";
import { RefreshOAuthButton } from "../buttons/refreshOAuthButton";
import { SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

export interface SocialMediaAccountRowProps
{
  socialNetworkAccount: SocialNetworkAccountAPI;
  source: SocialNetworkConnectionSourcePageType;
  otherSocialNetworksToSwitchTo?: SocialNetworkAccountAPI[];
  onRefreshAccountComplete?: ( accountType: SocialNetworkAccountType, succeeded: boolean, switchedAccount?: boolean ) => void;
}

export function SocialMediaAccountRow( props: SocialMediaAccountRowProps )
{
  const needsRefresh = socialNetworkAccountHelper.needsRefresh( props.socialNetworkAccount );
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );

  function shouldShowSwitchButton()
  {
    return size( props.otherSocialNetworksToSwitchTo ) > 1;
  }

  async function handleRefreshAccountComplete( succeeded: boolean, switchedAccount?: boolean )
  {
    await reloadSocialNetworkAccounts();

    if ( !!props.onRefreshAccountComplete )
    {
      props.onRefreshAccountComplete( props.socialNetworkAccount.account_type, succeeded, switchedAccount );
    }
  }

  async function reloadSocialNetworkAccounts()
  {
    const result = await businessServices.listSocialNetworkAccounts();
    socialNetworkAccountsContext.updateSocialNetworkAccounts( result.social_network_accounts );
  }

  function showSwitchButton()
  {
    if ( props.socialNetworkAccount.account_type === FACEBOOK_INSTAGRAM )
    {
      return <SwitchSocialNetworkAccountButton component={ConfirmInstagramAccount}
                                               onDrawerClosed={reloadSocialNetworkAccounts}
                                               source={props.source}
      />
    }
    else if ( props.socialNetworkAccount.account_type === FACEBOOK_PAGE )
    {
      return <SwitchSocialNetworkAccountButton component={ConfirmFacebookPageAccount}
                                               onDrawerClosed={reloadSocialNetworkAccounts}
                                               source={props.source}
      />
    }
  }

  function renderRefreshButton()
  {
    if ( socialNetworkAccountHelper.isTiktokAccount( props.socialNetworkAccount ) ||
         (socialNetworkAccountHelper.isYoutubeAccount( props.socialNetworkAccount )) )
    {
      return <RefreshOAuthButton socialNetworkAccount={props.socialNetworkAccount}
                                 handleRefreshComplete={handleRefreshAccountComplete}
                                 source={props.source}
                                 iconOnly={true}/>;
    }
    else
    {
      return <RefreshFacebookButton socialNetworkAccount={props.socialNetworkAccount}
                                    handleRefreshComplete={handleRefreshAccountComplete}
                                    source={props.source}
                                    iconOnly={true}
      />;
    }
  }

  return (<Stack
    sx={{
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    }}>
    <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
      <SocialAccountProfileIcon profileImageUrl={props.socialNetworkAccount.profile_image_url}
                                accountType={props.socialNetworkAccount.account_type}/>
      <Typography>{props.socialNetworkAccount.name}</Typography>
    </Stack>
    <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
      {needsRefresh && renderRefreshButton()}
      {shouldShowSwitchButton() && showSwitchButton()}
    </Box>
  </Stack>);
}