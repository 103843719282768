import { apptimizeWrapper } from "./apptimizeWrapper";
import { store } from "../../app/store";
import { getIsInternalUser } from "../user/userSlice";

let injectableApptimizeWrapper: typeof apptimizeWrapper;
export const PRICING_TEST_MAY_2024 = "pricingTestMay2024";
export const THREE_MONTHS_50_OFF_PARAM = "threeMonths50Off";
export const USE_VARIANT_OPEN_AI_MODEL = "useVariantOpenAIModel";
export const CORE_ASSISTANT_WITH_FORMATS = "useCoreAssistantWithFormats";
export const DEFAULT_TO_PLAN_TAB = "defaultToPlanTab";
export const USE_NEW_PRODUCT_SERVICE_PROMPT_RERUN = "useNewProductServicePromptRerun";
export const PROMOTE_EMAIL_OPTION = "promoteEmailOption";
export const SHOULD_USE_MELODIE_MUSIC = "shouldUseMelodieMusic";

const APPTIMIZE_BOOLEAN_FEATURE_FLAGS: string[] = [USE_VARIANT_OPEN_AI_MODEL,
                                                   CORE_ASSISTANT_WITH_FORMATS,
                                                   DEFAULT_TO_PLAN_TAB,
                                                   USE_NEW_PRODUCT_SERVICE_PROMPT_RERUN,
                                                   PROMOTE_EMAIL_OPTION,
                                                   SHOULD_USE_MELODIE_MUSIC];
export const FEATURE_FLAG_SUFFIX = "FeatureFlag";

function getBool( name: string, defaultValue: boolean ): boolean
{
  return getApptimizeWrapper().getBool( name, defaultValue );
}

function getApptimizeVariablesForServerRequestParams()
{
  return {
    use_variant_open_ai_model_feature: shouldUseVariantOpenAiModel(),
    use_core_assistant_with_formats_feature: shouldUseCoreAssistantWithFormats(),
    use_new_product_service_prompt_rerun_feature: shouldUseNewProductServicePromptRerun()
  }
}

function shouldUseVariantOpenAiModel()
{
  return isFeatureFlagOrVariableSet( USE_VARIANT_OPEN_AI_MODEL );
}

function shouldUseCoreAssistantWithFormats()
{
  return isFeatureFlagOrVariableSet( CORE_ASSISTANT_WITH_FORMATS );
}

function shouldDefaultToPlanTab()
{
  return isFeatureFlagOrVariableSet( DEFAULT_TO_PLAN_TAB )
}

function shouldAllowYoutube()
{
  const state = store.getState();
  return getIsInternalUser( state );
}

function shouldUseNewProductServicePromptRerun()
{
  return isFeatureFlagOrVariableSet( USE_NEW_PRODUCT_SERVICE_PROMPT_RERUN );
}

function shouldPromoteEmailOption()
{
  return isFeatureFlagOrVariableSet( PROMOTE_EMAIL_OPTION );
}

function shouldUseMelodieMusic()
{
  const internalUser = getIsInternalUser( store.getState() );
  return isFeatureFlagOrVariableSet( SHOULD_USE_MELODIE_MUSIC ) || internalUser;
}

function shouldAllowUserUploadedMusic()
{
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getString( name: string, defaultValue: string ): string
{
  return getApptimizeWrapper().getString( name, defaultValue );
}

function isFeatureEnabled( name: string ): boolean
{
  const theVariableFeatureFlag = name + FEATURE_FLAG_SUFFIX;
  return getApptimizeWrapper().isFeatureFlagEnabled( theVariableFeatureFlag );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isFeatureFlagOrVariableSet( key )
{
  return isFeatureEnabled( key ) || getBool( key, false );
}

function getApptimizeWrapper()
{
  return injectableApptimizeWrapper ? injectableApptimizeWrapper : apptimizeWrapper;
}

export const apptimizeVariables = {
  APPTIMIZE_BOOLEAN_FEATURE_FLAGS,
  getApptimizeVariablesForServerRequestParams,
  shouldAllowYoutube,
  shouldDefaultToPlanTab,
  shouldPromoteEmailOption,
  shouldUseMelodieMusic,
  shouldAllowUserUploadedMusic,
};
