import * as React from 'react';
import { useContext, useState } from 'react';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { CommonTrackAPI, MelodieGenreGroupOrPurposeAPI, MusicSelection } from "./musicSlice";
import { CircularProgress, Stack } from "@mui/material";
import { musicServices } from "../../services/music.services";
import { isEmpty, map } from "lodash";
import { MusicItem } from "./MusicItem";
import { NO_MUSIC_TRACK } from "../constants";
import { AudioDrawer } from "../ui/audioDrawer";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaContext } from "../context/postIdeaContext";
import { musicItemHelper } from "./musicItemHelper";
import { getSelectedTrackIdFromPostJson } from "../postIdea/postIdeaHelper";
import { EditMelodieMusicHeader } from "../editing/editMelodieMusicHeader";
import InfiniteScroll from "react-infinite-scroller";
import { FullScreenModalInnerWrap } from "../editing/fullScreenModalInnerWrap";

const HEADER_HEIGHT = "65px";

interface MelodieTrackListProps extends WithFullScreenDialogProps
{
  melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI;
  handleCloseAfterApplyingMusic: () => void;

}

function MelodieTrackList( props: MelodieTrackListProps )
{
  const melodieGenreGroupOrPurpose = props.melodieGenreGroupOrPurpose;
  const [pendingSelection, setPendingSelection] = useState<MusicSelection | undefined>( undefined );
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );
  const [tracks, setTracks] = React.useState<CommonTrackAPI[]>( [] );
  const [nextPage, setNextPage] = useState<number | undefined>( 1 );

  function handleMusicSelected( track: MusicSelection )
  {
    logMusicChanged( track.id );
    setPendingSelection( track );
  }

  function logMusicChanged( toTrack: string )
  {
    const currentTrack = pendingSelection ? pendingSelection.id : selectedTrackId;
    const fromTrack = isEmpty( currentTrack ) ? NO_MUSIC_TRACK : currentTrack;
    eventTracker.logEditPostMusicChanged( postIdea, fromTrack, toTrack );
  }

  function loadMore( page: number )
  {
    if ( !!page )
    {
      loadNextPageOfMelodieTracks( page );
    }
  }

  function loadNextPageOfMelodieTracks( page: number )
  {
    if ( !page )
    {
      return;
    }
    musicServices.getMelodieTracks( melodieGenreGroupOrPurpose.type, melodieGenreGroupOrPurpose.name, page ).then( ( response ) =>
    {
      eventTracker.logEditPostMusicMelodieSearchResultsShown( postIdea, melodieGenreGroupOrPurpose.type,
        melodieGenreGroupOrPurpose.name,
        page );

      let concatTracks = [...tracks, ...response.tracks];
      setTracks( concatTracks );
      if ( response.next_page )
      {
        setNextPage( response.next_page );
      }
      else
      {
        setNextPage( undefined );
      }
    } )
  }

  return (
    <FullPageMobileContainer>
      <EditMelodieMusicHeader title="Add music"
                              subtitle={melodieGenreGroupOrPurpose.name}
                              handleClose={props.handleClose}
                              useArrowIcon={true}
                              sx={{ height: HEADER_HEIGHT }}/>
      <FullScreenModalInnerWrap>
        <Stack width="100%" sx={{ overflow: "auto", height: `calc(100vh - ${HEADER_HEIGHT})` }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={!!nextPage}
            loader={<Stack key="0" alignItems="center" sx={{ my: 4 }}><CircularProgress className="spinner small" id="spinner"/></Stack>}
            useWindow={false}>
            {map( tracks, ( track ) => <MusicItem key={track.id} track={track} handleTrackSelected={handleMusicSelected}
                                                  selected={musicItemHelper.isTrackIdSelected( track.id, selectedTrackId, pendingSelection )}/> )}
          </InfiniteScroll>
        </Stack>
      </FullScreenModalInnerWrap>
      <AudioDrawer pendingSelection={pendingSelection} setPendingSelection={setPendingSelection}
                   closeAfterApplying={props.handleCloseAfterApplyingMusic}/>
    </FullPageMobileContainer>
  );
}

export const MelodieTracksFullScreenDialog = withFullScreenDialog( MelodieTrackList )
