import * as React from 'react';
import { useContext, useState } from 'react';
import { NO_MUSIC_TRACK, NO_MUSIC_TRACK_ID, Z_INDEX_DRAWER } from "../constants";
import { Box, Drawer, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { postIdeaServices } from "../../services/postIdeaServices";
import { clearAndStopAudioPlayerTrack } from "./uiSlice";
import { isEmpty } from "lodash";
import { eventTracker } from "../../helpers/eventTracker";
import { useDispatch } from "react-redux";
import { PostIdeaContext } from "../context/postIdeaContext";
import { getSelectedTrackIdFromPostJson } from "../postIdea/postIdeaHelper";
import { musicItemHelper } from "../music/musicItemHelper";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { MusicSelection } from "../music/musicSlice";

export interface AudioDrawerProps
{
  pendingSelection?: MusicSelection;
  setPendingSelection( pendingSelection: MusicSelection | undefined ): void;
  closeAfterApplying: () => void;
}

export function AudioDrawer( props: AudioDrawerProps )
{
  const dispatch = useDispatch();

  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );

  const [applyingMusic, setApplyingMusic] = useState( false );
  const pendingSelectionTrackId = props.pendingSelection ? props.pendingSelection.id : NO_MUSIC_TRACK_ID;
  const pendingSelectionDifferentThanInitial = props.pendingSelection && pendingSelectionTrackId !== selectedTrackId;
  const pendingSelection = props.pendingSelection;

  async function handleApplyPendingSelection()
  {
    if ( pendingSelection )
    {
      setApplyingMusic( true );
      logMusicApplied( pendingSelection.id );
      const newMusicTrack = musicItemHelper.getMusicTrackFromPendingSelection( pendingSelection );
      try
      {
        await postIdeaServices.replaceMusicWhenMelodieFeatureEnabled( postIdea.id, newMusicTrack );
        setApplyingMusic( false );
        props.closeAfterApplying();
      }
      catch (e)
      {
        setApplyingMusic( false );
        dispatch( setAlertMessage( errorAlert( "Error switching music" ) ) );
      }
    }
  }

  function handleCancelPendingSelection()
  {
    if ( pendingSelection )
    {
      logMusicCanceled( pendingSelection.id );
    }
    dispatch( clearAndStopAudioPlayerTrack() );
    props.setPendingSelection( undefined );
  }

  function logMusicApplied( toTrack: string )
  {
    const fromTrack = isEmpty( selectedTrackId ) ? NO_MUSIC_TRACK : selectedTrackId;
    eventTracker.logEditPostMusicApplied( postIdea, fromTrack, toTrack );
  }

  function logMusicCanceled( pendingTrack: string )
  {
    const backToTrack = isEmpty( selectedTrackId ) ? NO_MUSIC_TRACK : selectedTrackId;
    eventTracker.logEditPostMusicCanceled( postIdea, backToTrack, pendingTrack );
  }

  function currentMusicView()
  {
    if ( pendingSelection )
    {
      if ( pendingSelection.id === NO_MUSIC_TRACK_ID )
      {
        return <Typography variant={"body1"}>No music</Typography>
      }
      else
      {
        return <Typography variant={"body1"}>{pendingSelection.display_name}</Typography>
      }
    }
    else
    {
      return <Typography variant={"body1"}>Error retrieving music</Typography>
    }
  }

  return <Drawer
    anchor={"bottom"}
    open={pendingSelectionDifferentThanInitial}
    sx={{ zIndex: Z_INDEX_DRAWER }}
    variant="persistent">
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2, mb: 6 }}>
      <Typography variant={"h6"}>Apply new music selection?</Typography>
      {currentMusicView()}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 6 }}>
        <LoadingButton loading={applyingMusic}
                       startIcon={<HighlightOffIcon/>}
                       variant="contained" color="negative"
                       onClick={handleCancelPendingSelection}>Cancel</LoadingButton>
        <LoadingButton loading={applyingMusic}
                       startIcon={<CheckCircleOutlineIcon/>}
                       variant="contained"
                       color="positive"
                       onClick={handleApplyPendingSelection}>Apply</LoadingButton>
      </Box>
    </Box>
  </Drawer>
}
