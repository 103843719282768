import { MelodieTrackAPI, MusicSelection, TrackAPI } from "./musicSlice";
import { musicServices } from "../../services/music.services";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentMusicTrack, getCurrentMusicTrackPlaying, MusicTrack, setAudioPlayerPlaying, setAudioPlayerTrack } from "../ui/uiSlice";
import { RootState } from "../../app/store";
import { useEffect, useState } from "react";
import "./beatSyncIcon.scss"
import { musicItemHelper } from "./musicItemHelper";
import { TRACK_TYPE_EPIDEMIC_SOUND } from "../constants";
import { MusicItemView } from "./MusicItemView";

interface MusicItemProps
{
  track: MusicSelection;
  handleTrackSelected: ( track: MusicSelection ) => void;
  selected: boolean;
}

export function MusicItem( props: MusicItemProps )
{
  const dispatch = useDispatch();
  const selected = props.selected;
  const [trackUrl, setTrackUrl] = useState<string>();
  const [trackExpires, setTrackExpires] = useState<string>();
  const [trackStartTimeInSeconds, setTrackStartTimeInSeconds] = useState<number>();
  const currentAudioPlayerMusicTrack = useSelector( ( state: RootState ) => getCurrentMusicTrack( state ) );
  const currentAudioPlayerPlaying = useSelector( ( state: RootState ) => getCurrentMusicTrackPlaying( state ) );
  const doesTrackDownloadDataMatchAudioPlayer = currentAudioPlayerMusicTrack?.url === trackUrl;
  const hasTrackDownloadData = !!trackUrl;
  const [loading, setLoading] = useState( false );
  const showStopIcon = !!(selected && doesTrackDownloadDataMatchAudioPlayer && currentAudioPlayerPlaying);

  useEffect( () =>
  {
    if ( selected )
    {
      if ( !hasTrackDownloadData && musicItemHelper.isEpidemicSoundTrack( props.track ) )
      {
        const epidemicTrack = props.track as TrackAPI
        musicServices.getEpidemicSoundTrack( epidemicTrack.epidemic_id ).then( ( track ) =>
        {
          setTrackUrl( track.url );
          setTrackExpires( track.expires );
          const startTimeInSeconds = track.start_time_in_seconds ? track.start_time_in_seconds : 0;
          setTrackStartTimeInSeconds( startTimeInSeconds )
        } )
      }
      else
      {
        setTrackUrl( getUrlToPlayInClient() );
        setTrackStartTimeInSeconds( 0 )
      }
    }
  }, [selected] );

  function handleOnClick()
  {
    if ( !loading )
    {
      togglePlayingMusic();
    }
  }

  function getUrlToPlayInClient()
  {
    const streamUrl = (props.track as MelodieTrackAPI)?.stream_url;
    return streamUrl ? streamUrl : props.track.url;
  }

  const togglePlayingMusic = () =>
  {
    if ( currentAudioPlayerMusicTrack && doesTrackDownloadDataMatchAudioPlayer )
    {
      dispatch( setAudioPlayerPlaying( !currentAudioPlayerPlaying ) );
    }
    else
    {
      setLoading( true );
      if ( musicItemHelper.isEpidemicSoundTrack( props.track ) )
      {
        const epidemicTrack = props.track as TrackAPI
        if ( hasTrackDownloadData && trackExpires )
        {
          const startTimeInSeconds = trackStartTimeInSeconds ? trackStartTimeInSeconds : 0;
          const trackToSelect = {
            ...props.track,
            url: trackUrl,
            type: TRACK_TYPE_EPIDEMIC_SOUND,
            expires: trackExpires,
            start_time_in_seconds: startTimeInSeconds
          }
          props.handleTrackSelected( trackToSelect );
          playTrack( trackUrl, startTimeInSeconds );
          setLoading( false );
        }
        else
        {
          musicServices.getEpidemicSoundTrack( epidemicTrack.epidemic_id ).then( ( updatedTrack ) =>
          {
            const startTimeInSeconds = updatedTrack.start_time_in_seconds ? updatedTrack.start_time_in_seconds : 0;
            const trackUrl = updatedTrack.url;
            const trackToSelect = {
              ...updatedTrack,
              url: trackUrl,
              id: epidemicTrack.epidemic_id,
              type: TRACK_TYPE_EPIDEMIC_SOUND,
              expires: updatedTrack.expires,
              start_time_in_seconds: startTimeInSeconds
            } as TrackAPI
            props.handleTrackSelected( trackToSelect );

            setTrackUrl( trackUrl );
            setTrackExpires( updatedTrack.expires );
            setTrackStartTimeInSeconds( startTimeInSeconds )
            playTrack( trackUrl, startTimeInSeconds );
          } ).finally(
            () =>
            {
              setLoading( false );
            }
          );
        }
      }
      else if ( !!props.track.url )
      {
        props.handleTrackSelected( props.track );
        const urlToPlayInClient = getUrlToPlayInClient();
        setTrackUrl( urlToPlayInClient );
        playTrack( urlToPlayInClient, 0 );
        setLoading( false );
      }
    }
  };

  const playTrack = ( url: string, startTimeInSeconds: number ) =>
  {
    const musicTrack: MusicTrack = { url: url, startTimeInSeconds: startTimeInSeconds }
    dispatch( setAudioPlayerTrack( musicTrack ) );
    dispatch( setAudioPlayerPlaying( true ) );
  };

  return (
    <MusicItemView track={props.track}
                   handleClicked={handleOnClick}
                   selected={selected}
                   loading={loading}
                   showStopIcon={showStopIcon}
                   showBottomText={true}
    />
  );
}
