import { UserAuthData } from "../features/user/userSlice";
import { logToConsoleError } from "../features/utils/devLoggingHelper";

const LOCAL_STORAGE_AUTH_KEY = "alkai-a";

export const authHelper = {
  getAuthFromLocalStorage,
  setAuthInLocalStorage,
  removeAuthFromLocalStorage,
}

function getAuthFromLocalStorage()
{
  const rawData = window.localStorage.getItem( LOCAL_STORAGE_AUTH_KEY );
  if ( rawData )
  {
    try
    {
      return JSON.parse( rawData ) as UserAuthData;
    }
    catch ( e )
    {
      logToConsoleError( "Error parsing auth data from local storage", e );
    }
    return null;
  }

  return null;
}

function setAuthInLocalStorage( auth: UserAuthData )
{
  window.localStorage.setItem( LOCAL_STORAGE_AUTH_KEY, JSON.stringify( auth ) );
}

function removeAuthFromLocalStorage()
{
  window.localStorage.removeItem( LOCAL_STORAGE_AUTH_KEY );
}
