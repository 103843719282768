import React from "react";
import { Stack, Typography } from "@mui/material";
import { map } from "lodash";
import { PlanOutputFormatsPicker } from "../planSettings/planOutputFormatsPicker";
import { OutputFormatAPI } from "../../services/outputFormat.services";

export interface PostingStrategyQuizContentFormatProps
{
  id: string;
  value: string[];
  onQuestionAnswered: ( id: string, value: string[] ) => void;
}

export function PostingStrategyQuizContentFormat( props: PostingStrategyQuizContentFormatProps )
{
  function onOutputFormatChanged( newOutputFormatSlugs: OutputFormatAPI[] )
  {
    const newOutputFormatExtendPlanValues = map( newOutputFormatSlugs, ( outputFormat ) => outputFormat.extend_plan_value );
    props.onQuestionAnswered( props.id, newOutputFormatExtendPlanValues );
  }

  return (<Stack spacing={10}>
    <Stack>
      <Typography variant="subtitle1">Choose the <b>post formats</b> you want included in your weekly content plan.</Typography>
      <PlanOutputFormatsPicker businessOutputFormats={[]}
                               forceSelectAtLeastOne={true}
                               customOutputFormatChangedHandler={onOutputFormatChanged}/>
    </Stack>
  </Stack>);
}