import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { isUndefined, keys } from "lodash";
import { RootState } from "../../app/store";

export interface PostIdeaState
{
  postIdeas?: { [key: string]: PostIdeaDataAPI };
}

const initialState: PostIdeaState = {
  postIdeas: {},
}

export const postIdeaSlice = createSlice( {
  name: 'postIdea',
  initialState,
  reducers: {
    addPostIdea: ( state, action: PayloadAction<PostIdeaDataAPI> ) =>
    {
      if ( isUndefined( action.payload.id ) )
      {
        return { ...state };
      }

      const prevPostIdeas = state.postIdeas || {};
      const prevPostIdeaMatchingId = prevPostIdeas[action.payload.id] || {};
      return {
        ...state,
        postIdeas: {
          ...prevPostIdeas,
          [action.payload.id]: {
            ...prevPostIdeaMatchingId,
            ...action.payload
          },
        }
      }
    },
    clearPostIdeas: () =>
    {
      return {
        ...initialState
      }
    },
  }
} )

export const getPostIdea = ( state: RootState, postIdeaId: string | null | undefined ) =>
{
  if ( state.postIdea.postIdeas && !!postIdeaId )
  {
    return state.postIdea.postIdeas[postIdeaId];
  }
  else
  {
    return undefined;
  }
}

export const getPostIdeasCount = ( state: RootState ) =>
{
  return keys( state.postIdea.postIdeas ).length;
}

// Action creators are generated for each case reducer function
export const {
  addPostIdea,
  clearPostIdeas,
} = postIdeaSlice.actions

export default postIdeaSlice.reducer
