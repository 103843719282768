import { Helmet } from "react-helmet";
import { ROUTE_SEO } from "../constants";
import React, { useEffect, useRef, useState } from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FullPageMobileFooter } from "../layout/fullPageMobileFooter";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { PostingStrategyQuizIntro } from "./postingStrategyQuizIntro";
import { PostingStrategyQuizContentMix } from "./postingStrategyQuizContentMix";
import { PostingStrategyQuizSocialChannels } from "./postingStrategyQuizSocialChannels";
import { PostingStrategyQuizContentFormat } from "./postingStrategyQuizContentFormat";
import { QuizBreadCrumbs } from "./quizBreadCrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isEmpty, isNil, size } from "lodash";
import { businessServices } from "../../services/business.services";
import { eventTracker } from "../../helpers/eventTracker";

export interface PostingStrategyQuizProps
{
  onCloseFromModel?: () => void;
}

export function PostingStrategyQuiz( props: PostingStrategyQuizProps )
{
  const [questionIndex, setQuestionIndex] = useState( 0 );
  const [quizAnswers, setQuizAnswers] = useState( {} )
  const response = useRef<{}>( {} );

  useEffect( () =>
  {
    eventTracker.logOnboardingQuizStarted();
  }, [] );

  const questions = [
    {
      id: "intro",
      pageTitle: "Let's customize Alkai",
      contentComponent: PostingStrategyQuizIntro,
      required: false,
      onQuestionAnswered: handleQuestionResponse,
      isValid: ( currentAnswer ) =>
      {
        return true
      }
    },
    {
      id: "content_mix",
      pageTitle: "Content mix",
      contentComponent: PostingStrategyQuizContentMix,
      required: true,
      onQuestionAnswered: handleQuestionResponse,
      isValid: ( currentAnswer ) =>
      {
        return !isNil( currentAnswer )
      }
    },
    {
      id: "social_channels",
      pageTitle: "Social channels",
      contentComponent: PostingStrategyQuizSocialChannels,
      required: true,
      onQuestionAnswered: handleQuestionResponse,
      isValid: ( currentAnswer ) =>
      {
        return !isNil( currentAnswer ) && !isEmpty( currentAnswer )
      }
    },
    {
      id: "content_format",
      pageTitle: "Content format",
      contentComponent: PostingStrategyQuizContentFormat,
      required: true,
      onQuestionAnswered: handleQuestionResponse,
      isValid: ( currentAnswer ) =>
      {
        const choseOutputFormat = !isNil( currentAnswer ) && !isEmpty( currentAnswer );
        return choseOutputFormat && size( currentAnswer ) >= 1
      }
    },
  ];

  const currentQuestion = questions[questionIndex];
  const CurrentQuestionComponent = currentQuestion.contentComponent;

  function handleQuestionResponse( id: string, answer: number | string[] )
  {
    let copyOfQuizAnswers = { ...quizAnswers };
    copyOfQuizAnswers[id] = { answer }
    setQuizAnswers( copyOfQuizAnswers );
    response.current[id] = answer;
  }

  function nextPage()
  {
    const currentAnswer = response.current[currentQuestion.id];
    if ( questionIndex === 1 )
    {
      eventTracker.logOnboardingQuizQuestionOneComplete( currentAnswer );
    }
    else if ( questionIndex === 2 )
    {
      eventTracker.logOnboardingQuizQuestionTwoComplete( currentAnswer );
    }
    else if ( questionIndex === 3 )
    {
      eventTracker.logOnboardingQuizQuestionThreeComplete( currentAnswer );
    }

    if ( questionIndex < questions.length - 1 )
    {
      setQuestionIndex( questionIndex + 1 )
    }
    else
    {
      saveAndCloseQuiz();
    }
  }

  function previousPage()
  {
    if ( questionIndex > 0 )
    {
      setQuestionIndex( questionIndex - 1 );
    }
  }

  function handleBackClicked()
  {
    eventTracker.logOnboardingQuizBackClicked( currentQuestion.id );
    previousPage();
  }

  function shouldShowBackButton()
  {
    return questionIndex > 1;
  }

  function handleCloseClicked()
  {
    eventTracker.logOnboardingQuizClosed( currentQuestion.id );
    saveAndCloseQuiz();
  }

  function saveAndCloseQuiz()
  {
    businessServices.storePostStrategyQuizResults( response.current );
    if ( props.onCloseFromModel )
    {
      props.onCloseFromModel();
    }
  }

  function shouldDisableNext()
  {
    if ( currentQuestion.required )
    {
      const currentAnswer = response.current[currentQuestion.id];
      return !currentQuestion.isValid( currentAnswer )
    }
    return false
  }

  function getNextButtonText()
  {
    return questionIndex < questions.length - 1 ? "Next" : "Complete";
  }

  return (<>
    <Helmet>
      <title>{ROUTE_SEO.POSTING_STRATEGY_QUIZ.title}</title>
      <meta name="description" content={ROUTE_SEO.POSTING_STRATEGY_QUIZ.description}/>
    </Helmet>
    <Stack sx={{ p: 10 }} spacing={4}>
      <Stack direction="row" justifyContent="space-between">
        <IconButton onClick={handleBackClicked} sx={{ padding: "unset", visibility: shouldShowBackButton() ? "visible" : "hidden" }}>
          <ArrowBackIcon/>
        </IconButton>
        <IconButton onClick={handleCloseClicked} sx={{ padding: "unset" }}>
          <CloseIcon fontSize="inherit"/>
        </IconButton>
      </Stack>
      <QuizBreadCrumbs currentQuestionIndex={questionIndex} totalQuestions={questions.length}/>
      <Typography textAlign="center" variant="h6" sx={{ mt: 4 }}>{questions[questionIndex].pageTitle}</Typography>
    </Stack>

    <FullPageMobileContent sx={{ px: 10 }}>
      <CurrentQuestionComponent {...currentQuestion}
                                onQuestionAnswered={handleQuestionResponse}
                                value={response.current[currentQuestion.id]}/>
    </FullPageMobileContent>

    <FullPageMobileFooter>
      <Paper elevation={3}>
        <Button disabled={shouldDisableNext()}
                variant="contained"
                fullWidth={true}
                onClick={nextPage}
                sx={{ borderRadius: "unset" }}> {getNextButtonText()}</Button>
      </Paper>
    </FullPageMobileFooter>
  </>);
}