import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isGuestUser, isUserLoggedInAndVerified, isUserVerificationRequired, updateUserVerificationRequired } from "../user/userSlice";
import { Container, Stack } from "@mui/material";
import { eventTracker } from "../../helpers/eventTracker";
import { authenticationServices } from "../../services/authentication.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import {
  CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT,
  CREATE_ACCOUNT_SHOWN_VIA_PLAN_DIALOG,
  CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW,
  CREATE_ACCOUNT_SHOWN_VIA_SHARE_BUTTON,
  CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS,
  SEARCH_PARAM_KEY_NOTIFY,
  SEARCH_PARAM_KEY_SOURCE,
  SEARCH_PARAM_VALUE_UNKNOWN,
  SIGN_IN_PROVIDER_EMAIL,
  SIGN_IN_PROVIDER_PHONE_NUMBER
} from "../constants";
import { SignInAndVerify } from "./signInAndVerify";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { AlkaiSignInHeader } from "./alkaiSignInHeader";
import { CheckoutSignInHeader } from "./checkoutSignInHeader";
import AlertBanner from "../alert/alertBanner";
import { useSearchParams } from "react-router-dom";
import { size } from "lodash";
import { isCrmSource, isEmailCrmSource } from "../utils/crmSourceUtils";
import { TrustPilotWidget } from "./trustPilotWidget";

export interface CreateAccountOrSignInProps
{
  shownVia: string;
  shouldShowSignIn: boolean;
  onClose: () => void;
}

export function CreateAccountOrSignIn( props: CreateAccountOrSignInProps )
{

  const userIsLoggedInAndVerified = useSelector( ( state: RootState ) => isUserLoggedInAndVerified( state ) );
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParamSource = searchParams.get( SEARCH_PARAM_KEY_SOURCE ) || SEARCH_PARAM_VALUE_UNKNOWN;
  const isShownViaNotification = size( searchParams.getAll( SEARCH_PARAM_KEY_NOTIFY ) ) > 0;

  const isShownFromCrmSource = isCrmSource( searchParams.get( SEARCH_PARAM_KEY_SOURCE ) );
  const isShownFromEmailCrmSource = isEmailCrmSource( searchParams.get( SEARCH_PARAM_KEY_SOURCE ) );
  const initialSignInProvider = isShownFromEmailCrmSource ? SIGN_IN_PROVIDER_EMAIL : SIGN_IN_PROVIDER_PHONE_NUMBER;

  const isShownViaCheckout = props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT;
  const isShownViaShareButton = props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_SHARE_BUTTON;
  const isShownViaUserSettings = props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS;
  const isShownViaPlanDialog = props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_PLAN_DIALOG;
  const isEnteringAppForTheFirstTime = !(isShownViaUserSettings || isShownViaShareButton || isShownViaCheckout || isShownViaPlanDialog);

  const userVerificationRequired = useSelector( ( state: RootState ) => isUserVerificationRequired( state ) );
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const [signInAsGuestInProgress, setSignInAsGuestInProgress] = React.useState( false );

  const shouldShowCloseButton = ((!userIsLoggedInAndVerified && !userVerificationRequired)
                                 || (isGuest && !userVerificationRequired)) && !isShownViaNotification && !isShownFromCrmSource;

  useEffect( () =>
  {
    resetVerificationRequiredState();
    eventTracker.logCreateAccountShown( queryParamSource, props.shownVia )
  }, [] );

  function resetVerificationRequiredState()
  {
    if ( userVerificationRequired )
    {
      dispatch( updateUserVerificationRequired( false ) );
    }
  }

  const signInAsGuest = async () =>
  {
    if ( !signInAsGuestInProgress )
    {
      setSignInAsGuestInProgress( true );
      eventTracker.logSignInAsGuestClicked( queryParamSource, props.shownVia );
      await authenticationServices.signInAsGuest().catch( () =>
      {
        setSignInAsGuestInProgress( false );
        dispatch( setAlertMessage( errorAlert( "Hmm, something failed on our end. Please try again." ) ) )
      } );
    }
  }

  function handleSignInClose()
  {
    props.onClose();

    if ( !isShownViaPostPreview() && isEnteringAppForTheFirstTime )
    {
      signInAsGuest();
    }
  }

  function isShownViaPostPreview()
  {
    return props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW;
  }

  return (
    <Dialog
      fullScreen
      open={props.shouldShowSignIn}
    >
      <Container maxWidth="xs" sx={{ position: "relative", p: 10 }}>
        {shouldShowCloseButton &&
         <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ position: "absolute", top: 10, right: 10 }}>
           <IconButton onClick={handleSignInClose}>
             <ClearIcon/>
           </IconButton>
         </Stack>
        }
        {!isShownViaCheckout && <AlkaiSignInHeader shownVia={props.shownVia}/>}
        {isShownViaCheckout && <CheckoutSignInHeader/>}
        <SignInAndVerify source={queryParamSource}
                         shownVia={props.shownVia}
                         onSignInVerified={props.onClose}
                         onSignInClose={handleSignInClose}
                         signInAsGuestInProgress={signInAsGuestInProgress}
                         initialSignInProvider={initialSignInProvider}/>
        <TrustPilotWidget/>
      </Container>
      <AlertBanner/>
    </Dialog>
  );
}

