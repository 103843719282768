import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { ColorPaletteAPI } from "../../../services/colorPalette.services";
import { postIdeaServices } from "../../../services/postIdeaServices";
import { eventTracker } from "../../../helpers/eventTracker";
import { addPost } from "../../postIdea/postsSlice";
import { errorAlert, setAlertMessage } from "../../alert/alertSlice";
import { useDispatch } from "react-redux";
import { addOrUpdatePostIdeaFromMessage, getColorPaletteSlug } from "../../postIdea/postIdeaHelper";
import { ColorPaletteDrawer } from "./colorPaletteDrawer";
import { EDIT_COLOR_PALETTE_SOURCE_EDIT_POST } from "../../../helpers/trackingConstants";
import { PostIdeaContext } from "../../context/postIdeaContext";

export interface PostIdeaColorPaletteDrawerProps
{
  handleDrawerClose: () => void;
}

export function PostIdeaColorPaletteDrawer( props: PostIdeaColorPaletteDrawerProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const dispatch = useDispatch();
  const initialColorPaletteSlug = useRef( "" );
  const selectedColorPaletteSlug = getColorPaletteSlug( postIdea );

  useEffect( () =>
  {
    initialColorPaletteSlug.current = selectedColorPaletteSlug;
  }, [] );

  async function applyColorPalette( colorPalette: ColorPaletteAPI )
  {
    eventTracker.logEditPostColorPaletteSwitched( postIdea, colorPalette );
    await requestNewColorVariant( colorPalette.slug );
  }

  function requestNewColorVariant( colorPaletteSlug: string )
  {
    return postIdeaServices.generateNewColorVariant( postIdea.id, colorPaletteSlug ).then( ( response ) =>
    {
      dispatch( addPost( response.post_json ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem switching colors. Please try again." ) ) );
    } );
  }

  function handleCancelClicked( selectedColorPaletteSlug?: string )
  {
    if ( initialColorPaletteSlug && initialColorPaletteSlug.current !== selectedColorPaletteSlug )
    {
      requestNewColorVariant( initialColorPaletteSlug.current );
    }
    props.handleDrawerClose();
  }

  return (<ColorPaletteDrawer applyColorPalette={applyColorPalette}
                              selectedColorPaletteSlug={selectedColorPaletteSlug}
                              handleDrawerClose={props.handleDrawerClose}
                              handleCancelClicked={handleCancelClicked}
                              source={EDIT_COLOR_PALETTE_SOURCE_EDIT_POST}/>)

}
