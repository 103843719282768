import { Box, Typography } from "@mui/material";
import React from "react";

export interface LayoutUsageHintProps
{
  usageHint: string | undefined;
}

export function LayoutUsageHint( props: LayoutUsageHintProps )
{
  return <Box
    sx={{
      display: "flex",
      borderRadius: "5px",
      backgroundColor: "#EAEAFF",
      py: 1,
      px: 2,
      mx: "auto",
      mb: 5,
      width: "355px",
      alignItems: "center"
    }}>
    <Typography variant={"caption"} sx={{ mx: "auto", fontSize: "13px" }}>
      {props.usageHint}
    </Typography>
  </Box>;
}