import { Box, Button, SxProps, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import * as React from "react";
import { useCallback, useRef } from "react";
import { first, merge } from "lodash";
import { musicServices } from "../../services/music.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";

interface UploadMusicItemProps
{
  setLoading: ( value: boolean ) => void;
  setUploadedMusicTrack: ( data: any ) => void;
  onUploadMusicSelected: () => void;
  sx?: SxProps;
}

export function UploadMusicItem( props: UploadMusicItemProps )
{
  const dispatch = useDispatch();
  const inputButtonRef = useRef<HTMLInputElement>( null );

  function handleOnClick()
  {
    props.onUploadMusicSelected();
  }

  const handleFileSelection = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ( event ) =>
    {
      props.setLoading( true );

      const selectedMusicFile = event.currentTarget.files;
      // TODO - check if file is valid once we have metadata parsing

      let selectedFile = first( selectedMusicFile );
      if ( !!selectedFile )
      {
        const musicFileData = { file: selectedFile }
        await musicServices.uploadMusic( musicFileData ).then( ( data ) =>
        {
          props.setUploadedMusicTrack( data );
          console.log( data )
          // eventTracker.logUploadFontCompleted(props.source, data);
        } ).catch(
          ( error ) =>
          {
            dispatch( setAlertMessage( errorAlert( "Could not upload music. Please try again." ) ) );
            // eventTracker.logUploadFontFailed(props.source, error);
          }
        )
      }
      // else if ( is Invalid file )
      // {
      //   dispatch( setAlertMessage( errorAlert( "Invalid file. Please upload a valid .mp3 file." ) ) );
      // }

      props.setLoading( false );

      if ( inputButtonRef && !!inputButtonRef.current )
      {
        inputButtonRef.current.value = "";
      }
    },
    []
  );

  return (
    <Box sx={merge( { display: "flex", m: 5, alignItems: "center" }, props.sx )} onClick={handleOnClick}>
      <Button component="label" variant={"text"} sx={{p: 0}} onClick={handleOnClick}>
        <input ref={inputButtonRef} hidden type="file" accept=".mp3" id={"pick-music-input"} onChange={handleFileSelection}/>
        <DriveFolderUploadIcon sx={{ fontSize: 40, color: "black" }}/>
        <Typography variant={"h6"} sx={{ ml: 5, textTransform: "none", color: "black" }}>Upload music from device</Typography>
      </Button>
    </Box>
  );
}
