import * as React from 'react';
import { useContext, useState } from 'react';
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { Stack, Typography } from "@mui/material";
import { EditMelodieMusicFullScreenDialog } from "../editMelodieMusicTabContent";
import { PostIdeaContext } from "../../context/postIdeaContext";
import { getMusicDataFromPostJson } from "../../postIdea/postIdeaHelper";
import { MusicItemView } from "../../music/MusicItemView";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import { setRequestEditorPauseAudioTimestamp, setRequestEditorReloadWithAudioTimestamp } from "../../ui/uiSlice";
import { useDispatch } from "react-redux";
import { musicItemHelper } from "../../music/musicItemHelper";
import { NO_MUSIC_TRACK_ID } from "../../constants";
import { isEmpty } from "lodash";

export interface EditMusicDrawerProps
{
  handleDrawerClose: () => void;
}

export function EditMusicDrawer( props: EditMusicDrawerProps )
{
  const dispatch = useDispatch();
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const currentMusicData = getMusicDataFromPostJson( postIdea );
  const hasNoMusic = isEmpty( currentMusicData ) || currentMusicData.id === NO_MUSIC_TRACK_ID;
  const [showEditMusicDialog, setShowEditMusicDialog] = useState( hasNoMusic );
  const [showStopIcon, setShowStopIcon] = useState( false );

  const onApplyClick = () =>
  {
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    props.handleDrawerClose();
  }

  function handleOpenEditMusicDialog()
  {
    setShowEditMusicDialog( true );
    dispatch( setRequestEditorPauseAudioTimestamp( new Date().getTime() ) )
  }

  function handleCloseEditMusicDialog()
  {
    setShowEditMusicDialog( false );
    if ( hasNoMusic )
    {
      props.handleDrawerClose();
    }
  }

  function handleTogglePlayingMusic()
  {
    setShowStopIcon( !showStopIcon );
    if ( showStopIcon )
    {
      pauseMusicInEditor();
    }
    else
    {
      replayDesignInEditor();
    }
  }

  function replayDesignInEditor()
  {
    dispatch( setRequestEditorReloadWithAudioTimestamp( new Date().getTime() ) )
  }

  function pauseMusicInEditor()
  {
    dispatch( setRequestEditorPauseAudioTimestamp( new Date().getTime() ) )
  }

  function currentMusicView()
  {
    if ( !currentMusicData || (currentMusicData.id === NO_MUSIC_TRACK_ID) )
    {
      return <Typography variant={"body1"}>No music</Typography>
    }
    else
    {
      const commonTrackItemConvertedFromMusicData = musicItemHelper.getMusicTrackFromGlobalSettingsMusicData( currentMusicData );
      if ( !commonTrackItemConvertedFromMusicData )
      {
        return <Typography variant={"body1"}>Error retrieving music</Typography>
      }
      return <MusicItemView track={commonTrackItemConvertedFromMusicData}
                            handleClicked={handleTogglePlayingMusic}
                            selected={false}
                            loading={false}
                            showStopIcon={showStopIcon}
                            showBottomText={false}/>
    }
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto" }}>
      <DrawerTitle>Music</DrawerTitle>
      <Stack spacing={2} sx={{ alignItems: "center" }}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-around" }}>
          {currentMusicView()}
          <IconButton sx={{ width: 45, height: 45 }} onClick={handleOpenEditMusicDialog}><EditIcon/></IconButton>
        </Stack>
      </Stack>

      <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>
      {showEditMusicDialog && <EditMelodieMusicFullScreenDialog externalStateOpen={showEditMusicDialog}
                                                                manageOpenStateExternally={true}
                                                                hideTopBar={true}
                                                                handleClose={handleCloseEditMusicDialog}
                                                                handleCloseAfterApplyingMusic={onApplyClick}/>}
    </PostEditingDrawerContainer>
  );
}

