import * as React from 'react';
import { Box } from "@mui/material";

export interface FullScreenModalInnerWrapProps
{
  children: React.ReactNode;
}

export function FullScreenModalInnerWrap( props: FullScreenModalInnerWrapProps )
{
  return (
    <Box sx={{ mx: "auto", maxWidth: "400px", width: "100%"}}>
      {props.children}
    </Box>
  );
}
