import { Box, SxProps, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import { merge } from "lodash";
import { clearAndStopAudioPlayerTrack } from "../ui/uiSlice";

interface UploadedMusicItemProps
{
  handleOpenUploadedMusicDialog: () => void;
  sx?: SxProps;
}

export function UploadedMusicItem( props: UploadedMusicItemProps )
{
  const dispatch = useDispatch();

  function handleOnClick()
  {
    stopPlayingMusic();
    props.handleOpenUploadedMusicDialog();
  }

  const stopPlayingMusic = () =>
  {
    dispatch( clearAndStopAudioPlayerTrack() );
  };

  return (
    <Box sx={merge( { display: "flex", m: 5, borderRadius: "5px", alignItems: "center" }, props.sx )} onClick={handleOnClick}>
      <LibraryMusicOutlinedIcon sx={{fontSize: 40}}/>
      <Typography variant={"h6"} sx={{ ml: 5, color: "black" }}>Uploaded Music</Typography>
    </Box>
  );
}
