import { Stack, SxProps, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";

interface EditMelodieMusicHeaderProps
{
  title: string;
  subtitle?: string;
  handleClose?(): void;
  backIcon?: React.ReactNode;
  useArrowIcon?: boolean;
  sx?: SxProps;
}

export function EditMelodieMusicHeader( props: EditMelodieMusicHeaderProps )
{
  function getIcon()
  {
    if ( props.useArrowIcon )
    {
      return <IconButton
        aria-label="close"
        onClick={props.handleClose}>
        <ArrowBackIcon aria-label="back"/>
      </IconButton>;
    }
    return <IconButton
      aria-label="close"
      onClick={props.handleClose}>
      <CloseIcon fontSize="inherit"/>
    </IconButton>
  }

  return <FullPageMobileHeader sx={props.sx}>
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        {getIcon()}
        <Stack justifyContent="center" alignSelf="center">
          <Typography variant="h6" alignSelf="center">{props.title}</Typography>
          {!!props.subtitle && <Typography>{props.subtitle}</Typography>}
        </Stack>
      </Stack>
    </Stack>
  </FullPageMobileHeader>
}

