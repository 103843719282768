import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { BOTTOM_NAV_LAYOUTS_CONTENT_HEIGHT } from "../../constants";
import { map } from "lodash";
import { useDispatch } from "react-redux";
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { PostEditingDrawerScrollableContainer } from "../edtingDrawer/postEditingDrawerScrollableContainer";
import { addPost } from "../../postIdea/postsSlice";
import { BEAT_SYNC_ICON_TYPE, errorAlert, infoAlert, setAlertMessage } from "../../alert/alertSlice";
import { postIdeaServices } from "../../../services/postIdeaServices";
import { eventTracker } from "../../../helpers/eventTracker";
import { addOrUpdatePostIdeaFromMessage, getHolidayLayoutSlug } from "../../postIdea/postIdeaHelper";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { PostIdeaContext } from "../../context/postIdeaContext";
import { HolidayLayout } from "./holidayLayout";
import { HolidayLayoutPreviewAPI } from "../../../services/business.services";
import { holidayLayoutServices } from "../../../services/holidayLayout.services";
import { Button } from "@mui/material";
import { ConfirmDialog } from "../../ui/confirmDialog";

export interface HolidayLayoutDrawerProps
{
  handleDrawerClose: () => void;
}

export function HolidayLayoutDrawer( props: HolidayLayoutDrawerProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const [lastTimeScrollToSelected, setLastTimeScrollToSelected] = useState<number>( 0 );
  const [lastTimeRemoveScrollHint, setLastTimeRemoveScrollHint] = useState<number>( 0 );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastTimePreventScrollHintAnimation, setLastTimePreventScrollHintAnimation] = useState<number>( 0 );

  const [showLoader, setShowLoader] = useState( false );
  const [requestingNewLayout, setRequestingNewLayout] = useState<boolean>( false );
  const dispatch = useDispatch();

  const [holidayLayouts, setHolidayLayouts] = useState<HolidayLayoutPreviewAPI[]>( [] );
  const [selectedHolidayLayoutSlug, setSelectedHolidayLayoutSlug] = useState<string>( getHolidayLayoutSlug( postIdea ) || "" );
  const initialHolidayLayoutSlug = useRef( "" );
  const [showConfirmRevertDialog, setShowConfirmRevertDialog] = useState<boolean>( false );

  useEffect( () =>
  {
    initialHolidayLayoutSlug.current = selectedHolidayLayoutSlug;
  }, [] );

  function loadLayoutVariants()
  {
    holidayLayoutServices.getHolidayLayouts( postIdea.id ).then( ( response ) =>
    {
      setHolidayLayouts( response.layouts );
    } ).catch( ( reason ) =>
    {
      dispatch( setAlertMessage( errorAlert( "Could not fetch holiday templates" ) ) );
    } ).finally( () =>
    {
      setShowLoader( false );
    } );
  }

  useEffect( () =>
  {
    setShowLoader( true );
    loadLayoutVariants();
  }, [] );

  useEffect( () =>
  {
    setLastTimeScrollToSelected( new Date().getTime() )
  }, [holidayLayouts] );

  const onApplyClick = () =>
  {
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    if ( initialHolidayLayoutSlug && initialHolidayLayoutSlug.current !== selectedHolidayLayoutSlug )
    {
      requestNewLayout( initialHolidayLayoutSlug.current );
    }
    props.handleDrawerClose();
  }

  const requestNewLayout = ( holidayLayoutSlug: string ) =>
  {
    setRequestingNewLayout( true );
    return postIdeaServices.generateSpecificHolidayLayoutVariant( postIdea.id, holidayLayoutSlug ).then( ( response ) =>
    {
      dispatch( addPost( response.post_json ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );

      if ( !!response.added_music_for_beat_sync )
      {
        dispatch( setAlertMessage(
          infoAlert( "This layout is better with music, so I picked some music for you. You can change it if you want",
            'top',
            true,
            BEAT_SYNC_ICON_TYPE ) ) );
      }
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem switching holiday templates. Please try again." ) ) );
    } ).finally( () =>
      {
        setRequestingNewLayout( false );
      }
    );
  }
  const handleLayoutSelected = ( layout: HolidayLayoutPreviewAPI ) =>
  {
    setLastTimeRemoveScrollHint( new Date().getTime() );
    applyLayout( layout ).finally( () =>
      {
        setSelectedHolidayLayoutSlug( layout.slug );
      }
    );
  }

  const applyLayout = async ( layout: HolidayLayoutPreviewAPI ) =>
  {
    eventTracker.logEditPostHolidayLayoutSwitched( postIdea, layout );
    await requestNewLayout( layout.slug );
  }

  const getConfirmRevertMessage = () =>
  {
    return "Revert to the original template. This will delete any changes you have made to this draft."
  }

  const handleRevertClicked = () =>
  {
    setShowConfirmRevertDialog( true );
  }

  const handleCancelRevert = () =>
  {
    setShowConfirmRevertDialog( false );
  }

  const revertHolidayLayoutVariant = () =>
  {
    return postIdeaServices.revertHolidayLayoutVariant( postIdea.id, selectedHolidayLayoutSlug ).then( ( response ) =>
    {
      dispatch( addPost( response.post_json ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );
      props.handleDrawerClose();

    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem reverting to the original template. Please try again." ) ) );
    } ).finally( () =>
      {
        setShowConfirmRevertDialog( false );
        setRequestingNewLayout( false );
      }
    );
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto" }}>
      <DrawerTitle>Templates</DrawerTitle>
      <Button key={"default"} variant={"text"}
              sx={{ position: "absolute", top: "5px", left: " 75%", textTransform: "none", textDecoration: "underline" }}
              onClick={handleRevertClicked}>Revert</Button>
      <PostEditingDrawerScrollableContainer height={BOTTOM_NAV_LAYOUTS_CONTENT_HEIGHT}
                                            showLoader={showLoader}
                                            disableSelection={requestingNewLayout}
                                            lastTimeScrollToSelected={lastTimeScrollToSelected}
                                            lastTimeRemoveScrollHint={lastTimeRemoveScrollHint}
                                            lastTimePreventScrollHintAnimation={lastTimePreventScrollHintAnimation}>


        {map( holidayLayouts, ( layout ) =>
        {
          const selected = layout.slug === selectedHolidayLayoutSlug;
          return (
            <HolidayLayout key={layout.slug}
                           holidayLayout={layout}
                           handleLayoutSelected={handleLayoutSelected}
                           selected={selected}/>
          )
        } )}
      </PostEditingDrawerScrollableContainer>

      <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>
      <ConfirmDialog title={"Revert to original"}
                     message={getConfirmRevertMessage()}
                     open={showConfirmRevertDialog}
                     confirmText={"Revert"}
                     cancelText={"Cancel"}
                     onConfirm={revertHolidayLayoutVariant}
                     onCancel={handleCancelRevert}/>

    </PostEditingDrawerContainer>
  );
}
