import { createSlice } from '@reduxjs/toolkit'
import { MELODIE_ENTITY_TYPE_GENRE_GROUPS_API, MELODIE_ENTITY_TYPE_PURPOSES_API } from "../constants";


export type MusicSelection = CommonTrackAPI | TrackAPI | MelodieTrackAPI;
export interface CommonTrackAPI
{
  id: string,
  type: string,
  url: string,
  image?: string,
  display_name?: string,
  start_time_in_seconds?: number,
}

export interface MelodieTrackAPI extends CommonTrackAPI
{
  display_name: string,
  genre: string,
  artist_name: string,
  moods: string[],
  stream_url: string,
  duration_ms: number,
}

export interface TrackAPI extends CommonTrackAPI
{
  genre: string,
  artist_name: string,
  epidemic_id: string,
  highlights: string[],
  expires: string,
  is_beat_sync_recommended: boolean,
}

export interface MusicAPI
{
  tracks: TrackAPI[]
}

export interface GlobalSettingsMusicDataMetadataAPI
{
  id: string,
  url: string,
  expires?: string,
  display_name?: string,
  genre?: string,
  artist_name?: string,
  moods?: string[],
  stream_url?: string,
  duration_ms?: number,
}

export interface GlobalSettingsMusicDataAPI
{
  audio_url: string,
  type: string,
  id: string,
  startTimeInSeconds: number,
  metadata: GlobalSettingsMusicDataMetadataAPI,
  beats: any
}

export interface MelodieGenreGroupsAndPurposesAPI
{
  genre_groups: MelodieGenreGroupOrPurposeAPI[],
  purposes: MelodieGenreGroupOrPurposeAPI[],
}

export interface MelodieTracksAPI
{
  tracks: CommonTrackAPI[]
  next_page?: number
}

export type MelodieEntityType = typeof MELODIE_ENTITY_TYPE_GENRE_GROUPS_API | typeof MELODIE_ENTITY_TYPE_PURPOSES_API;

export interface MelodieGenreGroupOrPurposeAPI
{
  id: string,
  type: MelodieEntityType,
  name: string,
}

const initialState: MusicAPI = {
  tracks: []
};

export const musicSlice = createSlice( {
  name: 'music',
  initialState,
  reducers: {
    setTracks: ( state, action ) =>
    {
      return {
        ...state,
        ...action.payload
      };
    },
  },
} )

export const {
  setTracks,
} = musicSlice.actions

export default musicSlice.reducer
