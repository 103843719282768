import React from 'react'
import "./postIdea.scss";
import { SparsePostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { Stack } from "@mui/material";
import "./postPreviewThumbnail.scss";
import { PostIdeaMessage } from "./postIdeaMessage";
import { map } from "lodash";

export interface PostIdeaMessageProps
{
  sparsePostIdeas: SparsePostIdeaDataAPI[];
}

export function MultiplePostIdeaMessage( props: PostIdeaMessageProps )
{
  const sparsePostIdeaDataAPIS = props.sparsePostIdeas;
  const extraStyles = sparsePostIdeaDataAPIS.length > 1 ? { justifyContent: "center" } : { ml: "50px" };
  return (
    <Stack direction={"row"} sx={extraStyles} spacing={4}>
      {map( sparsePostIdeaDataAPIS, ( sparsePostIdeaDataAPI, index ) =>
      {
        return (
          <PostIdeaMessage key={sparsePostIdeaDataAPI.id} postIdeaId={sparsePostIdeaDataAPI.id} postIdeaGroupIndex={index} postIdeaGroupSize={sparsePostIdeaDataAPIS.length}/>
        )
      } )}
    </Stack>
  )
}
