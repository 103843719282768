import { filter, find, isNumber } from "lodash";

import {
  FACEBOOK_INSTAGRAM,
  FACEBOOK_PAGE,
  SocialNetworkAccountAPI,
  TIKTOK,
  TiktokBrandingAPI,
  TiktokInteractionOptionsAPI,
  YOUTUBE
} from "../services/business.services";
import { TiktokPrivacyOption } from "../features/constants";

export const socialNetworkAccountHelper = {
  needsRefresh,
  getConnectedFacebookPage,
  getConnectedInstagram,
  getConnectedTiktok,
  getConnectedYoutube,
  getSocialNetworksByType,
  getAllFacebookPageAccounts,
  getAllInstagramAccounts,
  getDisplayName,
  hasNoConnectedSocialNetworks,
  hasYetToConnectBothSocialNetworks,
  hasAnyConnectedSocialNetworks,
  getConnectedTiktokPrivacyOptions,
  getConnectedTiktokInteractionOptions,
  getConnectedTiktokBrandingDefaults,
  isYoutubeAccount,
  isTiktokAccount,
  isFacebookPageAccount,
  isFacebookInstagramAccount,
  updateSocialNetworkAccountInArrayOfAccounts
};

function needsRefresh( socialNetworkAccount?: SocialNetworkAccountAPI ): boolean
{
  return !!socialNetworkAccount &&
         socialNetworkAccount.enabled &&
         isNumber( socialNetworkAccount.invalid_token_error_count ) && socialNetworkAccount.invalid_token_error_count > 0;
}

function hasYetToConnectBothSocialNetworks( socialNetworkAccounts: SocialNetworkAccountAPI[] )
{
  const hasConnectedFacebookPages = !!getConnectedFacebookPage( socialNetworkAccounts );
  const hasConnectedInstagram = !!getConnectedInstagram( socialNetworkAccounts );
  return !hasConnectedFacebookPages || !hasConnectedInstagram;
}

function hasAnyConnectedSocialNetworks( socialNetworkAccounts: SocialNetworkAccountAPI[] )
{
  return !!find( socialNetworkAccounts, account => account.enabled );
}

function hasNoConnectedSocialNetworks( socialNetworkAccounts: SocialNetworkAccountAPI[] )
{
  const hasConnectedFacebookPages = !!getConnectedFacebookPage( socialNetworkAccounts );
  const hasConnectedInstagram = !!getConnectedInstagram( socialNetworkAccounts );
  return !hasConnectedFacebookPages && !hasConnectedInstagram;
}

function getConnectedFacebookPage( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === FACEBOOK_PAGE && account.enabled );
}

function getConnectedInstagram( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === FACEBOOK_INSTAGRAM && account.enabled );
}

function getConnectedTiktok( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === TIKTOK && account.enabled );
}

function getConnectedYoutube( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === YOUTUBE && account.enabled );
}

function getConnectedTiktokPrivacyOptions( tikTokAccount?: SocialNetworkAccountAPI ): TiktokPrivacyOption[] | undefined
{
  return tikTokAccount?.tiktok_account_details?.privacy_level_options;
}

function getConnectedTiktokInteractionOptions( tikTokAccount?: SocialNetworkAccountAPI ): TiktokInteractionOptionsAPI | undefined
{
  return tikTokAccount?.tiktok_account_details?.interaction_options;
}

function getConnectedTiktokBrandingDefaults( tikTokAccount?: SocialNetworkAccountAPI ): TiktokBrandingAPI | undefined
{
  return tikTokAccount?.tiktok_account_details?.branding_defaults;
}

function getAllFacebookPageAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === FACEBOOK_PAGE) );
}

function getAllInstagramAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === FACEBOOK_INSTAGRAM) );
}

function getSocialNetworksByType( socialNetworkAccounts: SocialNetworkAccountAPI[], accountType: string ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === accountType) );
}

function getDisplayName( socialNetwork: SocialNetworkAccountAPI )
{
  if ( socialNetwork.account_type === FACEBOOK_PAGE )
  {
    return "Facebook Page";
  }
  else if ( socialNetwork.account_type === FACEBOOK_INSTAGRAM )
  {
    return "Instagram";
  }
  else if ( socialNetwork.account_type === TIKTOK )
  {
    return "TikTok";
  }
  else if ( socialNetwork.account_type === YOUTUBE )
  {
    return "YouTube";
  }
  return "";
}

function isYoutubeAccount( socialNetworkAccount: SocialNetworkAccountAPI )
{
  return socialNetworkAccount.account_type === YOUTUBE;
}

function isTiktokAccount( socialNetworkAccount: SocialNetworkAccountAPI )
{
  return socialNetworkAccount.account_type === TIKTOK;
}

function isFacebookPageAccount( socialNetworkAccount: SocialNetworkAccountAPI )
{
  return socialNetworkAccount.account_type === FACEBOOK_PAGE;
}

function isFacebookInstagramAccount( socialNetworkAccount: SocialNetworkAccountAPI )
{
  return socialNetworkAccount.account_type === FACEBOOK_INSTAGRAM;
}

function updateSocialNetworkAccountInArrayOfAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[], updatedAccount: SocialNetworkAccountAPI )
{
  const updatedSocialNetworkAccounts = filter( socialNetworkAccounts, ( account ) => account.id !== updatedAccount.id );
  updatedSocialNetworkAccounts.push( updatedAccount )
  return updatedSocialNetworkAccounts;
}