import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import {
  ALKAI_TIKTOK_ERROR_CATEGORY_POSTING_LIMIT_REACHED,
  businessServices,
  SocialNetworkAccountType,
  TIKTOK,
  TiktokBrandingAPI,
  TiktokInteractionOptionsAPI
} from "../../services/business.services";
import * as React from "react";
import { useContext, useEffect } from "react";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { SocialMediaAccountRow } from "../userSettings/SocialMediaAccountRow";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { Box, Tooltip } from "@mui/material";
import { ShareDestinationToggle } from "./ShareDestinationToggle";
import { TiktokPrivacyOption } from "../constants";
import { TiktokPrivacySection } from "./TiktokPrivacySection";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { isStaticPostIdea } from "../postIdea/postIdeaHelper";
import { isEmpty } from "lodash";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import {
  getInitialTiktokBrandingDefaults,
  getInitialTiktokInteractionSettings,
  getInitialTiktokPrivacyOption,
  getPreviousTiktokPost,
  getTiktokAccountLevelInteractionSettings,
  TiktokSharingSettings
} from "../sharing/tiktokSharingSettings";
import { EDIT_POST_PAGE_SOURCE } from "../../helpers/trackingConstants";
import { ConnectOAuthSocialNetworkButton } from "../buttons/connectOAuthSocialNetworkButton";
import TiktokIcon from "../../assets/TiktokIcon";
import { useDispatch } from "react-redux";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";

export interface TiktokDirectShareRowProps
{
  postIdea: PostIdeaDataAPI;
  previousShareBatch?: ShareBatchAPI;
  showProgress?: ( title: string, accountType: SocialNetworkAccountType ) => void;
  hideProgress?: () => void;
  updateSharingSettings?: ( settings: TiktokSharingSettings ) => void;
}

export function TiktokDirectShareRow( props: TiktokDirectShareRowProps )
{
  const [tiktokCreatorInfoError, setTiktokCreatorInfoError] = React.useState<string | null>( null );
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const socialNetworkAccounts = socialNetworkAccountsContext.socialNetworkAccounts;
  const connectedTiktok = socialNetworkAccountHelper.getConnectedTiktok( socialNetworkAccounts );
  const [showTiktokPrivacySection, setShowTiktokPrivacySection] = React.useState( false );
  const [tiktokSelected, setTiktokSelected] = React.useState( false );
  const [needsToFetchCreatorInfo, setNeedsToFetchCreatorInfo] = React.useState( false );
  const previousTiktokPost = getPreviousTiktokPost( props.previousShareBatch, connectedTiktok );
  const [privacyOptions, setPrivacyOptions] = React.useState( socialNetworkAccountHelper.getConnectedTiktokPrivacyOptions( connectedTiktok ) || [] );
  const [privacySetting, setPrivacySetting] = React.useState( getInitialTiktokPrivacyOption( previousTiktokPost, privacyOptions ) );
  const [brandingSettings, setBrandingSettings] = React.useState( getInitialTiktokBrandingDefaults( connectedTiktok ) );
  const [interactionOptions, setInteractionOptions] = React.useState( getTiktokAccountLevelInteractionSettings( connectedTiktok ) );
  const [interactionSettings, setInteractionSettings] = React.useState( getInitialTiktokInteractionSettings( previousTiktokPost, connectedTiktok ) );
  const [showTiktokDisclosureSection, setShowTiktokDisclosureSection] = React.useState(
    brandingSettings.brand_content_toggle || brandingSettings.brand_organic_toggle );
  const readyToShare = !tiktokSelected || !showTiktokDisclosureSection || brandingSettings.brand_content_toggle
                       || brandingSettings.brand_organic_toggle;
  const shouldShowPrivacySection = !isEmpty( privacyOptions );
  const dispatch = useDispatch();

  useEffect( () =>
  {
    onMount();
  }, [] );

  useEffect( () =>
  {
    if ( needsToFetchCreatorInfo )
    {
      fetchTiktokAccountCreatorInfo();
      setNeedsToFetchCreatorInfo( false );
    }
  }, [needsToFetchCreatorInfo] );

  useEffect( () =>
  {
    const sharingSettings: TiktokSharingSettings = {
      tiktokSettings: {
        privacy_level: privacySetting,
        ...brandingSettings,
        ...interactionSettings
      },
      selectedToShare: tiktokSelected,
      readyToShare: readyToShare,
    }

    if ( props.updateSharingSettings )
    {
      props.updateSharingSettings( sharingSettings );
    }
  }, [privacySetting, brandingSettings, interactionSettings, readyToShare, tiktokSelected] )

  useEffect( () =>
  {
    const availableOptions = socialNetworkAccountHelper.getConnectedTiktokPrivacyOptions( connectedTiktok );
    if ( !!availableOptions )
    {
      setPrivacyOptions( availableOptions );
      setPrivacySetting( getInitialTiktokPrivacyOption( previousTiktokPost, availableOptions ) );
    }
    setInteractionOptions( getTiktokAccountLevelInteractionSettings( connectedTiktok ) );

  }, [connectedTiktok] );

  async function onMount()
  {
    await fetchTiktokAccountCreatorInfo();
  }

  async function fetchTiktokAccountCreatorInfo()
  {
    if ( !!connectedTiktok && !socialNetworkAccountHelper.needsRefresh( connectedTiktok ) )
    {
      const updatedSocialNetworkAccountsResponse = await businessServices.refreshTiktokCreatorInfo( connectedTiktok.id )
      const updatedTiktokAccount = updatedSocialNetworkAccountsResponse.tiktok_account;
      if ( !!updatedTiktokAccount )
      {
        const updatedSocialNetworkAccounts =
          socialNetworkAccountHelper.updateSocialNetworkAccountInArrayOfAccounts( socialNetworkAccounts, updatedTiktokAccount );
        socialNetworkAccountsContext.updateSocialNetworkAccounts( updatedSocialNetworkAccounts );
      }
      if ( !!updatedSocialNetworkAccountsResponse.error_category )
      {
        setTiktokCreatorInfoError( updatedSocialNetworkAccountsResponse.error_category );
      }
      else
      {
        setTiktokCreatorInfoError( null );
      }
    }
  }

  function tiktokStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setTiktokSelected( checked );

    if ( checked )
    {
      setShowTiktokPrivacySection( true );
    }
    else
    {
      setShowTiktokPrivacySection( false );
    }
  }

  function hideLoadingOverlay()
  {
    if ( !!props.hideProgress )
    {
      props.hideProgress();
    }
  }

  function getTiktokSwitch()
  {

    function handleTiktokConnected( accountType: SocialNetworkAccountType, switchedAccount?: boolean )
    {
      setNeedsToFetchCreatorInfo( true );
      hideLoadingOverlay();
      setTiktokSelected( true );
      setShowTiktokPrivacySection( true );

      if ( switchedAccount )
      {
        dispatch( setAlertMessage( warningAlert( "You are now connected to a new TikTok account." ) ) );
      }
    }

    function handleTiktokConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleTiktokConnectionStarted()
    {
      if ( !!props.showProgress )
      {
        props.showProgress( "Connecting to TikTok...", TIKTOK );
      }
    }

    function handleRefreshTiktokAccountComplete( accountType: SocialNetworkAccountType, succeeded: boolean, switchedAccount?: boolean )
    {
      if ( succeeded )
      {
        setNeedsToFetchCreatorInfo( true );

        if ( switchedAccount )
        {
          dispatch( setAlertMessage( warningAlert( "You are now connected to a new TikTok account" ) ) );
        }
      }
    }

    function getHintForTiktokErrorCategory( errorCategory: string )
    {
      switch ( errorCategory )
      {
        case ALKAI_TIKTOK_ERROR_CATEGORY_POSTING_LIMIT_REACHED:
          return "You've reached the daily posting limit on TikTok. Please try again tomorrow.";
        default:
          return "There was a problem connecting to TikTok. Please try again later.";
      }
    }

    if ( !!connectedTiktok )
    {
      if ( socialNetworkAccountHelper.needsRefresh( connectedTiktok ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedTiktok}
                                      onRefreshAccountComplete={handleRefreshTiktokAccountComplete}
                                      source={EDIT_POST_PAGE_SOURCE}
        />;
      }

      const accountIcon = <SocialAccountProfileIcon profileImageUrl={connectedTiktok.profile_image_url}
                                                    accountType={TIKTOK}/>;

      if ( !!tiktokCreatorInfoError )
      {
        const errorHint = getHintForTiktokErrorCategory( tiktokCreatorInfoError );
        return <Tooltip title={errorHint}
                        enterTouchDelay={50}>
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            <ShareDestinationToggle icon={accountIcon}
                                    disabled={true}
                                    label={connectedTiktok.name}
                                    checked={tiktokSelected}
                                    onChange={tiktokStatusChanged}/>
          </Box>
        </Tooltip>
      }

      return <ShareDestinationToggle icon={accountIcon}
                                     label={connectedTiktok.name}
                                     checked={tiktokSelected}
                                     onChange={tiktokStatusChanged}/>;
    }

    return <ConnectOAuthSocialNetworkButton accountType={TIKTOK}
                                            source={EDIT_POST_PAGE_SOURCE}
                                            iconType={TiktokIcon}
                                            handleConnectionSucceeded={handleTiktokConnected}
                                            handleConnectionFailed={handleTiktokConnectionFailed}
                                            handleConnectionStarted={handleTiktokConnectionStarted}/>;
  }

  function handleInteractionSettingsChanged( interactionSettings: TiktokInteractionOptionsAPI )
  {
    setInteractionSettings( interactionSettings );
  }

  function handleTiktokPrivacyChanged( privacy: TiktokPrivacyOption )
  {
    setPrivacySetting( privacy );
  }

  function handleTiktokBrandingSettingsChanged( branding: TiktokBrandingAPI )
  {
    setBrandingSettings( branding );
  }

  function handleTiktokDisclosureSectionToggled( showDisclosureSection: boolean )
  {
    setShowTiktokDisclosureSection( showDisclosureSection );
  }

  function renderTiktokPrivacySection()
  {
    return shouldShowPrivacySection && <TiktokPrivacySection
      isStaticPost={isStaticPostIdea( props.postIdea )}
      privacy={privacySetting}
      privacyOptions={privacyOptions}
      interactionOptions={interactionOptions}
      interactionSettings={interactionSettings}
      brandingSettings={brandingSettings}
      showDisclosureSection={showTiktokDisclosureSection}
      handlePrivacyChanged={handleTiktokPrivacyChanged}
      handleDisclosureSectionToggled={handleTiktokDisclosureSectionToggled}
      handleInteractionSettingsChanged={handleInteractionSettingsChanged}
      handleBrandingSettingsChanged={handleTiktokBrandingSettingsChanged}/>;
  }

  return (<>
    {getTiktokSwitch()}
    {showTiktokPrivacySection && renderTiktokPrivacySection()}
  </>)

}