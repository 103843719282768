import { AudioPlayer, AudioPlayerDispatchProps, AudioPlayerProps } from "./AudioPlayer";
import { AppDispatch, RootState } from "../../app/store";
import { connect } from "react-redux";
import { clearAndStopAudioPlayerTrack, getCurrentMusicTrack, getCurrentMusicTrackPlaying, setAudioPlayerPlaying } from "../ui/uiSlice";

const mapStateToProps = ( storeState: RootState ): AudioPlayerProps =>
{
  return {
    currentMusic: getCurrentMusicTrack( storeState ),
    currentMusicPlaying: getCurrentMusicTrackPlaying( storeState ),
  };
};

const mapDispatchToProps = ( dispatch: AppDispatch ): AudioPlayerDispatchProps =>
  {
    return {
      clearAudioPlayerMusicTrack: () =>
      {
        dispatch( clearAndStopAudioPlayerTrack() );
      },
      setAudioPlayerPlaying: ( playing: boolean ) =>
      {
        dispatch( setAudioPlayerPlaying( playing ) );
      },
    };
  }
;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( AudioPlayer );
