import { asyncPostJsonToApi, getToApi, postJsonToApi, postToApi } from "./requestManager";
import { store } from "../app/store";
import {
  currentUserBusinessId,
  setBrandStyle,
  setClientUiFlagsJson,
  setFacebookPages,
  setInstagramAccounts,
  updateBusiness
} from "../features/business/businessSlice";
import { addToMediaLibrary, clearProgressState } from "../features/ui/uiSlice";
import { MediaAssetSource, TiktokPrivacyOption } from "../features/constants";
import { concat, merge } from "lodash";
import { ColorPaletteAPI } from "./colorPalette.services";
import { FontSetAPI } from "./fontSet.services";
import { PostAPIWrapper } from "../features/postIdea/postsSlice";
import { FontAPI } from "./font.services";
import { DateUtils } from "../features/utils/dateUtils";
import { formatISO } from "date-fns";
import { ShareBatchAPI, TiktokSettingsAPI, YoutubeSettingsAPI } from "./postIdeaServices";
import { OutputFormatAPI } from "./outputFormat.services";
import { userHelper } from "../features/user/userHelper";
import { eventTracker } from "../helpers/eventTracker";
import { errorAlert, setAlertMessage, successAlert } from "../features/alert/alertSlice";
import { ENV_CONFIG } from "../constants";
import { UploadedMusicData } from "./music.services";

export const businessServices = {
  createNewBusiness,
  clientFacebookConnect,
  getSocialNetworkOauthConnectUrl,
  getSocialNetworkOauthRefreshUrl,
  launchOauthViaWindow,
  getAllInstagramAccounts,
  getAllFacebookPages,
  switchInstagramAccount,
  switchFacebookPage,
  fetchMediaLibrary,
  addMediaAssetToLibrary,
  addCuratedMediaAssetToLibrary,
  addFontToLibrary,
  addMusicToLibrary,
  disableMediaAssetInLibrary,
  generateAiImage,
  addMediaFromWebsite,
  updateBusinessInState,
  addPlannedPost,
  removePlannedPost,
  getGeneratedPlanIdeaTitleForDate,
  markPostingStreakAsRead,
  getMediaStyles,
  updateMediaStyle,
  getBrandStyle,
  updateBrandStyle,
  getBrandCardPreview,
  updateBrandCardPreview,
  updateBusinessClientUiFlagsJson,
  submitStyleQuizResponse,
  pregenerateSinglePlanSuggestion,
  pregenerateMultiplePlanSuggestions,
  listPlanSuggestions,
  extendPlan,
  getPlannerDataForWeek,
  getCurrentStreak,
  storePostStrategyQuizResults,
  updateUnsubscribedFromNotifications,
  listSocialNetworkAccounts,
  listBusinesses,
  getHasScheduledPostsForAccount,
  updateName,
  switchBusiness,
  getSelectBusinessesEligibility,
  setEnabledBusinesses,
  enableBusiness,
  getAddBusinessesEligibility,
  ensureChatThread,
  getPlanSettings,
  updatePlanSettings,
  refreshTiktokCreatorInfo,
  fetchUploadedMusic
}
const CONNECT_FACEBOOK_PATH = "connect_facebook";
const GET_SOCIAL_NETWORK_OAUTH_URL_PATH = "get_social_network_oauth_url";
const BUSINESSES_BASE_PATH = "businesses";
const BUSINESSES_ADD_WEBSITE_MEDIA_PATH = "add_media_from_website";
const BUSINESS_MEDIA_LIBRARY_PATH = "media_library";
const BUSINESS_FONT_LIBRARY_PATH = "font_library";
const BUSINESS_MUSIC_LIBRARY_PATH = "music_library";
const BUSINESS_MEDIA_LIBRARY_ADD_PATH = BUSINESS_MEDIA_LIBRARY_PATH + "/add";
const BUSINESS_MEDIA_LIBRARY_ADD_CURATED_PATH = BUSINESS_MEDIA_LIBRARY_PATH + "/add_curated";
const BUSINESS_FONT_LIBRARY_ADD_PATH = BUSINESS_FONT_LIBRARY_PATH + "/add";
const BUSINESS_MUSIC_LIBRARY_ADD_PATH = BUSINESS_MUSIC_LIBRARY_PATH + "/add";
const BUSINESS_MUSIC_LIBRARY_UPLOADED_PATH = BUSINESS_MUSIC_LIBRARY_PATH + "/uploaded";
const BUSINESS_ADD_PLAN_IDEA_FOR_DATE_PATH = "add_new_plan_idea_for_date";
const BUSINESS_GENERATE_PLAN_IDEA_TITLE_FOR_DATE_PATH = "generate_plan_idea_title_for_date";
const BUSINESS_MARK_POSTING_STREAK_AS_READ_PATH = "mark_posting_streak_as_read";
const BUSINESS_REMOVE_PLAN_IDEA_FOR_DATE_PATH = "remove_plan_idea_for_date";
const BUSINESS_MEDIA_LIBRARY_GENERATE_AI_IMAGE_PATH = BUSINESS_MEDIA_LIBRARY_PATH + "/generate_ai_image";
const BUSINESS_MEDIA_LIBRARY_DISABLE_MEDIA_ASSET_PATH = BUSINESS_MEDIA_LIBRARY_PATH + "/disable_media_asset";
const BUSINESS_MEDIA_STYLES_PATH = "media_styles";
const BUSINESS_UPDATE_MEDIA_STYLE_PATH = "update_media_style";
const BUSINESS_BRAND_STYLE_PATH = "brand_style";
const BUSINESS_UPDATE_BRAND_STYLE_PATH = "update_brand_style";
const BUSINESS_CHANGE_BRAND_SLIDE_CONFIG_BRAND_STYLE_PATH = "change_brand_slide_config";
const BRAND_SLIDE_PREVIEW_PATH = "brand_slide_preview";
const BUSINESS_UPDATE_CLIENT_UI_FLAGS_JSON_PATH = "update_client_ui_flags_json";
const BUSINESS_UPDATE_STYLE_QUIZ = "update_style_quiz";
const BUSINESS_PREGENERATE_PLAN_SUGGESTIONS_PATH = "pregenerate_plan_suggestions";
const BUSINESS_PLAN_SUGGESTIONS_PATH = "plan_suggestions";
const BUSINESS_EXTEND_PLAN_PATH = "extend_plan";
const STORE_POST_STRATEGY_QUIZ_RESULTS_PATH = "store_post_strategy_quiz_results";
const CREATE_NEW_BUSINESS_PATH = "create_new_business";
const PLAN_FOR_WEEK_PATH = "plan_for_week";
const PLAN_SETTINGS_PATH = "plan_settings";
const UPDATE_PLAN_SETTINGS_PATH = "update_plan_settings";
const CURRENT_STREAK_PATH = "current_streak";
const ALL_INSTAGRAM_ACCOUNTS_PATH = "all_instagram_accounts";
const SWITCH_INSTAGRAM_ACCOUNT_PATH = "switch_instagram_account";
const ALL_FACEBOOK_PAGES_ACCOUNTS_PATH = "all_facebook_page_accounts";
const SWITCH_FACEBOOK_PAGE_ACCOUNT_PATH = "switch_facebook_page_account";
const ENSURE_CHAT_THREAD_PATH = "ensure_chat_thread";
const UPDATE_UNSUBSCRIBED_NOTIFICATIONS_PATH = "update_unsubscribed_notifications";
const SOCIAL_NETWORK_ACCOUNTS_PATH = "social_network_accounts";
const HAS_SCHEDULED_POSTS_FOR_ACCOUNT_PATH = "has_scheduled_posts_for_account";
const UPDATE_PATH = "update";
const LIST_BUSINESSES_PATH = "list_businesses";
const SWITCH_BUSINESSES_PATH = "switch_business";
const SELECT_BUSINESSES_ELIGIBILITY_PATH = "select_businesses_eligibility";
const ADD_BUSINESSES_ELIGIBILITY_PATH = "add_business_eligibility";
const ENABLE_BUSINESSES_PATH = "enable_businesses";
const ENABLE_BUSINESS_PATH = "enable_business";
const REFRESH_TIKTOK_CREATOR_INFO_PATH = "refresh_tiktok_creator_info"

export const PLAN_SUGGESTION_STATUS_FAILED_TO_SEND_PLAN_SUGGESTION_NOTIFICATION = "failed_to_send_plan_suggestion_notification";
export const PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA = "failed_to_generate_post_idea";
export const PLAN_SUGGESTION_STATUS_NOTIFIED = "notified";
export const PLAN_SUGGESTION_STATUS_OPENED = "opened";
export const PLAN_SUGGESTION_STATUS_QUEUED = "queued";

export const SHARING_STATUS_SENDING = "sending";
export const SHARING_STATUS_FAILED = "failed";
export const SHARING_STATUS_SENT = "sent";
export const SHARING_STATUS_SCHEDULED = "scheduled";
export const SHARING_STATUS_CANCELED = "canceled";

export const FACEBOOK_PAGE = "FacebookPage"
export const FACEBOOK_INSTAGRAM = "FacebookInstagram"
export const TIKTOK = "TikTok"
export const YOUTUBE = "YouTube"

export const ALKAI_TIKTOK_ERROR_CATEGORY_POSTING_LIMIT_REACHED = "daily_posting_limit_reached";

export type PlanSuggestionStatus = typeof PLAN_SUGGESTION_STATUS_FAILED_TO_SEND_PLAN_SUGGESTION_NOTIFICATION |
  typeof PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA |
  typeof PLAN_SUGGESTION_STATUS_NOTIFIED |
  typeof PLAN_SUGGESTION_STATUS_OPENED |
  typeof PLAN_SUGGESTION_STATUS_QUEUED;

export type PostSharingStatus =
  typeof SHARING_STATUS_SENDING
  | typeof SHARING_STATUS_FAILED
  | typeof SHARING_STATUS_SCHEDULED
  | typeof SHARING_STATUS_SENT
  | typeof SHARING_STATUS_CANCELED;

export type SocialNetworkAccountType =
  typeof FACEBOOK_PAGE |
  typeof FACEBOOK_INSTAGRAM |
  typeof TIKTOK |
  typeof YOUTUBE;

export interface CreateBusinessAPI
{
  business: RefreshBusinessAPI
}

export interface ListBusinessAPI
{
  businesses: RefreshBusinessAPI[]
}

export interface SwitchBusinessAPI
{
  business: RefreshBusinessAPI;
}

export interface EnableBusinessAPI
{
  business: RefreshBusinessAPI;
}

export interface EnsureChatThreadAPI
{
  business: RefreshBusinessAPI;
}

export interface OauthUrlAPI
{
  oauth_url: string;
}

export interface OauthMessageAPI
{
  data: string;
  success: boolean;
  switched_account: boolean;
  error_reason?: string;
}

export interface RefreshBusinessAPI
{
  id: string;
  slug: string;
  business_id: string;
  facebook_connected: boolean;
  has_instagram_accounts: boolean;
  has_multiple_instagram_accounts: boolean;
  instagram_account_id: string;
  instagram_account_name: string;
  instagram_account_profile: string;
  has_facebook_page_accounts: boolean;
  has_multiple_facebook_page_accounts: boolean;
  facebook_page_account_id: string;
  facebook_page_account_name: string;
  facebook_page_account_profile: string;
  has_tiktok_accounts: boolean;
  has_multiple_tiktok_accounts: boolean;
  tiktok_account_id: string,
  tiktok_account_name: string,
  tiktok_account_profile: string,
  tiktok_account_details: TiktokAccountDetailsAPI,
  posts_per_week_goal?: number;
  weekly_schedule?: WeeklyScheduleAPI;
  has_plan_ever_been_extended?: boolean;
  name: string;
  description: string;
  has_approved_topics: boolean;
  first_post_drafted: boolean;
  brand_style?: BrandStyleAPI;
  created_at: string;
  client_ui_flags_json: ClientUiFlagsJsonAPI;
  active_business_media_styles_count: number;
  pending_streak?: CurrentStreakAPI;
  unsubscribed_notifications: UnsubscribedNotificationsAPI;
  enabled: boolean;
}

export interface TiktokAccountDetailsAPI
{
  privacy_level_options: TiktokPrivacyOption[];
  interaction_options: TiktokInteractionOptionsAPI;
  branding_defaults: TiktokBrandingAPI;
}

export interface TiktokBrandingAPI
{
  brand_organic_toggle: boolean;
  brand_content_toggle: boolean;
}

export interface TiktokInteractionOptionsAPI
{
  comment_disabled: boolean;
  duet_disabled: boolean;
  stitch_disabled: boolean;
}

export interface ClientUiFlagsJsonAPI
{
  has_seen_custom_plan_announcement_dialog?: boolean;
  has_seen_media_library_announcement_dialog?: boolean;
}

export interface WeeklyScheduleAPI
{
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface UnsubscribedNotificationsAPI
{
  unsubscribed_all_notifications: boolean;
}

export interface InstagramAccountsAPI
{
  instagram_accounts: InstagramAccountAPI[];
}

export interface InstagramAccountAPI
{
  id: string;
  instagram_account_name: string;
  instagram_account_profile: string;
}

export interface FacebookPagesAPI
{
  facebook_page_accounts: FacebookPageAPI[];
}

export interface FacebookPageAPI
{
  id: string;
  facebook_page_account_name: string;
  facebook_page_account_profile: string;
}

export interface BusinessMediaLibraryApi
{
  media_assets: MediaAssetAPI[];
  next_page?: number;
}

export interface BusinessMediaLibraryQueryParams
{
  page: number;
  logo_picking?: boolean;
}

export interface BusinessAddUrlToLibraryResponseApi
{
  media_asset: MediaAssetAPI;
}

export interface MediaAssetAPI
{
  id: string;
  url: string;
  poster_url: string;
  source: MediaAssetSource;
  width: number;
  height: number;
  is_video: boolean;
  tracking_data?: MediaAssetTrackingDataAPI;
}

export interface MediaAssetTrackingDataAPI
{
  [key: string]: any;
}

export interface BusinessAddFontUrlToLibraryResponseApi
{
  font: FontAPI;
}

export interface BusinessAddMusicUrlToLibraryResponseApi
{
  music: UploadedMusicData;
}

export interface BrandCardPreviewAPI
{
  post: PostAPIWrapper;
  brand_slide_config: BrandSlideConfigAPI;
}

export interface PreviousWeekActionPayloadAPI
{
  weekStartDate: string;
  dates: PlannerDateAPI[];
}

export interface SocialNetworkAccountsResponseAPI
{
  social_network_accounts: SocialNetworkAccountAPI[];
}

export interface RefreshTiktokCreatorInfoAPI
{
  tiktok_account: SocialNetworkAccountAPI;
  error_category?: AlkaiTiktokErrorCategory;
}

export type AlkaiTiktokErrorCategory =
  typeof ALKAI_TIKTOK_ERROR_CATEGORY_POSTING_LIMIT_REACHED;

export interface HasScheduledPostsAPI
{
  has_scheduled_posts: boolean;
}

export interface SelectBusinessesEligibilityAPI
{
  should_force_select_businesses: boolean;
  max_number_of_businesses: number;
}

export interface AddBusinessesEligibilityAPI
{
  can_add_more_businesses: boolean;
  upgrade_required: boolean;
  scheduled_downgrade?: boolean;
}

async function updateName( name: string ): Promise<RefreshBusinessAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( UPDATE_PATH );
  return postToApi<RefreshBusinessAPI>( endPoint, { name } ).then(
    ( data ) =>
    {
      updateBusinessInState( data );
      return data;
    }
  );
}

async function clientFacebookConnect( facebookId: number, facebookAccessToken: string ): Promise<RefreshBusinessAPI>
{
  const jsonBody = {
    facebook_user_id: facebookId.toString(),
    facebook_access_token: facebookAccessToken,
  }

  const endPoint = buildCurrentBusinessMemberUrl( CONNECT_FACEBOOK_PATH );
  return postJsonToApi<RefreshBusinessAPI>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      if ( data.has_instagram_accounts )
      {
        businessServices.getAllInstagramAccounts().then(
          ( data ) =>
          {
            setInstagramAccounts( data );
          }
        )
      }
      if ( data.has_facebook_page_accounts )
      {
        businessServices.getAllFacebookPages().then(
          ( data ) =>
          {
            setFacebookPages( data );
          }
        )
      }
      return data;
    }
  );
}

async function getSocialNetworkOauthConnectUrl( accountType: string ): Promise<OauthUrlAPI>
{
  const endpoint = buildCurrentBusinessMemberUrl( GET_SOCIAL_NETWORK_OAUTH_URL_PATH );
  return getToApi<OauthUrlAPI>( endpoint, { account_type: accountType } ).then( ( data ) =>
  {
    return data
  } );
}

async function getSocialNetworkOauthRefreshUrl( socialNetworkAccount: SocialNetworkAccountAPI )
{
  const endpoint = buildCurrentBusinessMemberUrl( GET_SOCIAL_NETWORK_OAUTH_URL_PATH );
  const params = {
    account_type: socialNetworkAccount.account_type,
    account_id: socialNetworkAccount.id
  }

  return getToApi<OauthUrlAPI>( endpoint, params ).then( ( data ) =>
  {
    return data
  } );

}

function launchOauthViaWindow( url: string, handleConnectionSucceeded?: ( switchedAccount: boolean ) => void,
                               handleConnectionFailed?: ( errorReason?: string ) => void )
{
  const oauthWindow = window.open( url, "socialNetworkOauthWindow" );
  if ( oauthWindow )
  {
    cleanupOauthHelpers();
    const listener = messageListener( oauthWindow, handleConnectionSucceeded, handleConnectionFailed );
    window.addEventListener( "message", listener, false );
    window.oauthWindowEventListener = listener;
    scheduleOauthWindowDetector( oauthWindow, handleConnectionFailed );
  }
  else
  {
    store.dispatch( setAlertMessage( errorAlert( "Please allow pop-up windows and try again to continue connecting your account." ) ) );
    if ( !!handleConnectionFailed )
    {
      handleConnectionFailed( "Popup blocker blocked Oauth window creation." );
    }
  }
}

const messageListener = ( popup: Window, handleConnectionSucceeded?: ( switchedAccount: boolean ) => void,
                          handleConnectionFailed?: ( errorReason?: string ) => void ) =>
{
  return ( event: MessageEvent<OauthMessageAPI> ) =>
  {
    if ( event.origin === ENV_CONFIG.baseUrl )
    {
      if ( event.data.success )
      {
        store.dispatch( setAlertMessage( successAlert( "Connected successfully!" ) ) );
        if ( !!handleConnectionSucceeded )
        {
          handleConnectionSucceeded( event.data.switched_account );
        }
      }
      else
      {
        const errorReason = event.data.error_reason;
        store.dispatch( setAlertMessage( errorAlert( "There was a problem connecting. Please try again." ) ) );
        if ( !!handleConnectionFailed )
        {
          handleConnectionFailed( errorReason );
        }
      }
      cleanupOauthHelpers();
      popup.close();
    }
  }
}

function cleanupOauthHelpers()
{
  if ( window.oauthWindowEventListener )
  {
    window.removeEventListener( "message", window.oauthWindowEventListener );
    window.oauthWindowEventListener = undefined;
  }

  if ( window.oauthWindowDetectorTimeout )
  {
    clearTimeout( window.oauthWindowDetectorTimeout );
    window.oauthWindowDetectorTimeout = undefined;
  }
}

function scheduleOauthWindowDetector( targetWindow: Window, failureCallback?: ( errorReason?: string ) => void )
{
  if ( !targetWindow.closed )
  {
    if ( window.oauthWindowDetectorTimeout )
    {
      clearTimeout( window.oauthWindowDetectorTimeout );
      window.oauthWindowDetectorTimeout = undefined;
    }
    window.oauthWindowDetectorTimeout = setTimeout( () =>
    {
      scheduleOauthWindowDetector( targetWindow, failureCallback );
    }, 1500 );
  }
  else if ( window.oauthWindowDetectorTimeout )
  {
    clearTimeout( window.oauthWindowDetectorTimeout );
    window.oauthWindowDetectorTimeout = undefined;
    store.dispatch( setAlertMessage( errorAlert( "There was a problem connecting. Please try again." ) ) );
    if ( !!failureCallback )
    {
      failureCallback( "User closed Oauth popup window." );
    }
  }
}

async function getSelectBusinessesEligibility()
{
  const endPoint = buildUrl( [SELECT_BUSINESSES_ELIGIBILITY_PATH] );
  return getToApi<SelectBusinessesEligibilityAPI>( endPoint );
}

async function getAddBusinessesEligibility()
{
  const endPoint = buildUrl( [ADD_BUSINESSES_ELIGIBILITY_PATH] );
  return getToApi<AddBusinessesEligibilityAPI>( endPoint );
}

async function getAllInstagramAccounts(): Promise<InstagramAccountsAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( ALL_INSTAGRAM_ACCOUNTS_PATH );
  return getToApi<InstagramAccountsAPI>( endPoint ).then(
    ( data ) =>
    {
      store.dispatch( setInstagramAccounts( data ) );
      return data;
    }
  );
}

function setEnabledBusinesses( businessSlugs: string[] )
{
  const endpoint = buildUrl( [ENABLE_BUSINESSES_PATH] );
  return postJsonToApi( endpoint, {}, { business_slugs: businessSlugs } );
}

function enableBusiness( businessSlug: string )
{
  const endpoint = buildBusinessMemberUrl( businessSlug, ENABLE_BUSINESS_PATH );
  return postJsonToApi<EnableBusinessAPI>( endpoint );
}

async function switchInstagramAccount( instagramAccountId: string ): Promise<RefreshBusinessAPI>
{
  const jsonBody = {
    instagram_account_id: instagramAccountId
  }

  const endPoint = buildCurrentBusinessMemberUrl( SWITCH_INSTAGRAM_ACCOUNT_PATH );
  return postJsonToApi<RefreshBusinessAPI>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      updateBusinessInState( data );
      return data;
    }
  );
}

async function getAllFacebookPages(): Promise<FacebookPagesAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( ALL_FACEBOOK_PAGES_ACCOUNTS_PATH );
  return getToApi<FacebookPagesAPI>( endPoint ).then(
    ( data ) =>
    {
      store.dispatch( setFacebookPages( data ) );
      return data;
    }
  );
}

async function switchFacebookPage( facebookPageId: string ): Promise<RefreshBusinessAPI>
{
  const jsonBody = {
    facebook_page_account_id: facebookPageId
  }

  const endPoint = buildCurrentBusinessMemberUrl( SWITCH_FACEBOOK_PAGE_ACCOUNT_PATH );
  return postJsonToApi<RefreshBusinessAPI>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      updateBusinessInState( data );
      return data;
    }
  );
}

function updateBusinessInState( data: RefreshBusinessAPI )
{
  if ( !!data )
  {
    store.dispatch( updateBusiness( data ) );
  }
}

async function ensureChatThread(): Promise<EnsureChatThreadAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( ENSURE_CHAT_THREAD_PATH );
  return postJsonToApi<EnsureChatThreadAPI>( endPoint, {} ).then(
    ( data ) =>
    {
      const business = data.business;
      updateBusinessInState( business );
      return data;
    }
  );
}

function fetchMediaLibrary( queryParams: BusinessMediaLibraryQueryParams ): Promise<BusinessMediaLibraryApi>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_LIBRARY_PATH );
  return getToApi<BusinessMediaLibraryApi>( endPoint, queryParams ).then(
    ( data ) =>
    {
      store.dispatch( addToMediaLibrary( data ) );
      return data;
    }
  );
}

function addMediaAssetToLibrary( new_media_url: string, media_source: string, is_logo: boolean = false,
                                 trackingData?: MediaAssetTrackingDataAPI ): Promise<MediaAssetAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_LIBRARY_ADD_PATH );
  const jsonBody = { new_media_url, media_source, is_logo, tracking_data: trackingData }
  return postJsonToApi<BusinessAddUrlToLibraryResponseApi>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      return data.media_asset;
    }
  );
}

async function addCuratedMediaAssetToLibrary( curatedMediaAssetId: string ): Promise<BusinessAddUrlToLibraryResponseApi>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_LIBRARY_ADD_CURATED_PATH );
  const jsonBody = { curated_media_asset_id: curatedMediaAssetId }
  return await postJsonToApi<BusinessAddUrlToLibraryResponseApi>( endPoint, {}, jsonBody );
}

function addFontToLibrary( newFontUrl: string, fontFamily: string, displayName: string ): Promise<FontAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_FONT_LIBRARY_ADD_PATH );
  const jsonBody = {
    new_font_url: newFontUrl,
    font_family: fontFamily,
    display_name: displayName
  }

  return postJsonToApi<BusinessAddFontUrlToLibraryResponseApi>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      return data.font;
    }
  ).finally( () =>
    {
      store.dispatch( clearProgressState() );
    }
  );

}

function addMusicToLibrary( newMusicUrl: string,
                            displayName: string,
                            artistName: string,
                            durationInMs: number ): Promise<UploadedMusicData>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MUSIC_LIBRARY_ADD_PATH );
  const jsonBody = {
    s3_url: newMusicUrl,
    display_name: displayName,
    artist_name: artistName,
    duration_in_ms: durationInMs
  }

  return postJsonToApi<BusinessAddMusicUrlToLibraryResponseApi>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      return data.music;
    }
  ).finally( () =>
    {
      store.dispatch( clearProgressState() );
    }
  );
}

function fetchUploadedMusic(): Promise<UploadedMusicData[]>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MUSIC_LIBRARY_UPLOADED_PATH );
  return getToApi<UploadedMusicData[]>( endPoint );
}

function addPlannedPost( date: Date, postIdeaTitle: string, outputFormatSlug?: string, postingPlanDateId?: string )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_ADD_PLAN_IDEA_FOR_DATE_PATH );
  const jsonBody = {
    posting_plan_date_id: postingPlanDateId,
    date: DateUtils.formatDateToYearMonthDay( date ),
    post_idea_title: postIdeaTitle,
    is_holiday: false,
    output_format_slug: outputFormatSlug,
  };
  return postJsonToApi( endPoint, {}, jsonBody );
}

function removePlannedPost( postingPlanDate: PostingPlanDateAPI )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_REMOVE_PLAN_IDEA_FOR_DATE_PATH );
  const date = postingPlanDate.date;
  const postIdeaTitle = postingPlanDate.post_idea_title;
  const jsonBody = {
    date,
    post_idea_title: postIdeaTitle,
  };
  return postJsonToApi( endPoint, {}, jsonBody );
}

function buildUrl( pathPieces: string[] )
{
  return concat( [BUSINESSES_BASE_PATH], pathPieces ).join( "/" );
}

function addMediaFromWebsite( websiteUrl: string ): Promise<BusinessMediaLibraryApi>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESSES_ADD_WEBSITE_MEDIA_PATH );
  const jsonBody = { website: websiteUrl }
  return asyncPostJsonToApi<any>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      return data;
    }
  );
}

function buildCurrentBusinessMemberUrl( path: string )
{
  const state = store.getState()
  const business_id = currentUserBusinessId( state );
  if ( !business_id )
  {
    throw new Error( "Business not found." );
  }

  return buildBusinessMemberUrl( business_id, path );
}

function buildBusinessMemberUrl( businessSlugOrId: string, path: string )
{
  return buildUrl( [businessSlugOrId, path] );
}

function disableMediaAssetInLibrary( media_asset_id: string ): Promise<any>
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_LIBRARY_DISABLE_MEDIA_ASSET_PATH );
  const jsonBody = { media_asset_id };
  return postJsonToApi<any>( endPoint, {}, jsonBody );
}

async function createNewBusiness(): Promise<RefreshBusinessAPI>
{
  return postToApi<CreateBusinessAPI>( CREATE_NEW_BUSINESS_PATH ).then(
    ( data ) =>
    {
      const business = data.business;
      userHelper.clearBusinessSpecificData( store.dispatch )
      updateBusinessInState( business );
      return business;
    } );
}

export interface PlannerAPI extends PlanStatusAPI
{
  dates: PlannerDateAPI[],
  week_start_date: string,
  posting_streak_message?: string;
  posting_streak?: PostingStreakAPI | null;
  has_previous_week?: boolean;
  has_next_week?: boolean;
}

export interface PlanSettingsAPI
{
  plan_ready: boolean;
  weekly_schedule: WeeklyScheduleAPI;
  output_formats: OutputFormatAPI[];
}

export interface PlanStatusAPI
{
  last_extended_at?: string;
  plan_extension_status?: string;
}

export interface CurrentStreakAPI
{
  posting_streak_message: string;
  posting_streak: PostingStreakAPI | null;
  streak_days: StreakDayAPI[];
}

export interface StreakDayAPI
{
  date: string;
  completed: boolean;
}

export interface PreviousPlannerWeekAPI
{
  weeks: { [key: string]: PlannerDateAPI[] };
}

export interface PostingStreakAPI
{
  id: string,
  streak_from_previous_weeks: number,
  notification_status: string,
}

export interface MarkPostingStreakAsReadAPI
{
  posting_streak: PostingStreakAPI;
}

export interface PlanIdeaTitleForDateAPI
{
  plan_idea_title: string
}

export interface PlannerDateAPI
{
  date_with_timezone: string,
  suggestions: PlanSuggestionAPI[]
  share_batches: ShareBatchAPI[]
  posting_plan_dates: PostingPlanDateAPI[]
  business_holidays: BusinessHolidayAPI[]
}

export interface PostingPlanDateAPI
{
  id: string;
  date: string;
  post_idea_title: string;
  plan_suggestion: PlanSuggestionAPI;
  output_format?: OutputFormatAPI;
  content_goal?: string;
}

export interface BusinessHolidayAPI
{
  id: string;
  date: string;
  holiday_name: string;
  holiday_date_id: string;
  has_post_idea: boolean;
  holiday_collection: HolidayCollectionAPI;
}

export interface HolidayCollectionAPI
{
  id: string;
  layouts: HolidayLayoutPreviewAPI[];
}

export interface HolidayLayoutPreviewAPI
{
  slug: string;
  image_url: string;
}

export interface SocialNetworkPostAPI
{
  id: string,
  social_network_account_id: string,
  account_type: SocialNetworkAccountType,
  send_status: PostSharingStatus,
  tiktok_settings?: TiktokSettingsAPI,
  youtube_settings?: YoutubeSettingsAPI,
}

export interface SocialNetworkAccountAPI
{
  id: string,
  name: string,
  profile_image_url: string,
  account_type: SocialNetworkAccountType,
  external_account_id: string,
  invalid_token_error_count: number,
  enabled: boolean,
  tiktok_account_details?: TiktokAccountDetailsAPI,
}

export interface PlanSuggestionAPI
{
  post_idea_id?: string,
  date_with_timezone: string,
  scheduled_for?: string,
  generated: boolean,
  status: PlanSuggestionStatus,
  post_idea_title?: string,
  pregenerated_at?: string,
  output_format_slug?: string,
  content_goal?: string,
}

export interface BusinessMediaStyleAPI
{
  id: number;
  preset_name: string;
  display_name: string;
  active: boolean;
  preview_url: string;
  description: string;
}

export interface MediaStylesAPI
{
  media_styles: BusinessMediaStyleAPI[];
}

export interface BrandStyleAPI
{
  id: string,
  business_name?: string,
  website?: string,
  social_username?: string,
  optional_text?: string,
  logo?: MediaAssetAPI,
  enabled: boolean,
  use_brand_in_brand_slide: boolean,
  use_brand_in_post_idea: boolean,
  color_palette?: ColorPaletteAPI,
  font_set?: FontSetAPI,
}

export interface BrandSlideConfigAPI
{
  slug: string,
  brand_slide_config_class: string,
  rank: number,
  status: string,
}

export interface UpdateClientUiFlagsJsonResponseAPI
{
  client_ui_flags_json: ClientUiFlagsJsonAPI;
}

export interface UpdateStyleQuizResponseAPI
{
  business: RefreshBusinessAPI,
  media_styles: BusinessMediaStyleAPI[];
}

export interface PregeneratePlanSuggestionsResponseAPI
{
  success: boolean;
}

export interface ListPlanSuggestionsResponseAPI
{
  plan_suggestions: PlanSuggestionAPI[];
}

export interface BrandStyleUpdateParams
{
  brand_style_id?: string,
  business_name?: string,
  website?: string,
  social_username?: string,
  optional_text?: string,
  enabled?: boolean,
  logo_media_asset_id?: string,
  color_palette_slug?: string,
  font_set_slug?: string,
  use_brand_in_brand_slide?: boolean,
  use_brand_in_post_idea?: boolean,
}

export interface BusinessMediaStyleUpdateParams
{
  active?: boolean,
}

export interface PostStrategyQuizResultsAPI
{
  content_mix?: number,
  social_channels?: string[],
  content_format?: string[],
}

async function getPlannerDataForWeek( weekStartDate: Date )
{
  const formattedDate = formatISO( weekStartDate )
  const endPoint = buildCurrentBusinessMemberUrl( PLAN_FOR_WEEK_PATH );
  return getToApi<PlannerAPI>( endPoint, { week_start_date: formattedDate } );
}

async function getPlanSettings()
{
  const endPoint = buildCurrentBusinessMemberUrl( PLAN_SETTINGS_PATH );
  return getToApi<PlanSettingsAPI>( endPoint );
}

async function updatePlanSettings( weeklySchedule?: WeeklyScheduleAPI, outputFormatSlugs?: string[] )
{
  const endPoint = buildCurrentBusinessMemberUrl( UPDATE_PLAN_SETTINGS_PATH );

  let jsonBody = { weekly_schedule: weeklySchedule }
  if ( outputFormatSlugs )
  {
    jsonBody = merge( jsonBody, { output_format_slugs: outputFormatSlugs } );
  }

  return postJsonToApi<PlanSettingsAPI>( endPoint, {}, jsonBody );
}

async function getCurrentStreak()
{
  const endPoint = buildCurrentBusinessMemberUrl( CURRENT_STREAK_PATH );
  return getToApi<CurrentStreakAPI>( endPoint );
}

async function getGeneratedPlanIdeaTitleForDate( date: Date )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_GENERATE_PLAN_IDEA_TITLE_FOR_DATE_PATH );
  const jsonBody = {
    date
  };
  return postJsonToApi<PlanIdeaTitleForDateAPI>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      return data;
    }
  );
}

async function getMediaStyles()
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_STYLES_PATH );
  return getToApi<MediaStylesAPI>( endPoint );
}

async function updateMediaStyle( businessMediaStyleId: number, params: BusinessMediaStyleUpdateParams )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_UPDATE_MEDIA_STYLE_PATH );
  return postJsonToApi<BusinessMediaStyleAPI>( endPoint, {}, { business_media_style_id: businessMediaStyleId, ...params } );
}

async function getBrandStyle()
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_BRAND_STYLE_PATH );
  return getToApi<BrandStyleAPI>( endPoint ).then( ( data ) =>
  {
    store.dispatch( setBrandStyle( data ) );
    return data;
  } );
}

function verifyBrandStyleIdExists( params: BrandStyleUpdateParams )
{
  if ( params.brand_style_id === undefined )
  {
    throw new Error( "brand_style_id is required to update brand style" );
  }
}

async function updateBrandStyle( params: BrandStyleUpdateParams )
{
  verifyBrandStyleIdExists( params );

  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_UPDATE_BRAND_STYLE_PATH );

  return postToApi<BrandStyleAPI>( endPoint, params ).then( ( data ) =>
  {
    store.dispatch( setBrandStyle( data ) );
    return data;
  } );
}

async function getBrandCardPreview( designAspectRatio?: string )
{
  const endPoint = buildCurrentBusinessMemberUrl( BRAND_SLIDE_PREVIEW_PATH );

  const queryParams = { design_aspect_ratio: designAspectRatio };

  return getToApi<BrandCardPreviewAPI>( endPoint, queryParams ).then( ( data ) =>
  {
    return data.post;
  } );
}

async function updateBrandCardPreview( direction: string, designAspectRatio: string )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_CHANGE_BRAND_SLIDE_CONFIG_BRAND_STYLE_PATH );

  const queryParams = {
    design_aspect_ratio: designAspectRatio,
    direction
  };
  return postToApi<BrandCardPreviewAPI>( endPoint, queryParams );
}

async function markPostingStreakAsRead( posting_streak: PostingStreakAPI )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MARK_POSTING_STREAK_AS_READ_PATH );
  const jsonBody = {
    posting_streak_id: posting_streak.id
  };
  return postJsonToApi<MarkPostingStreakAsReadAPI>( endPoint, {}, jsonBody );
}

interface BusinessGenerateAIImageAPI
{
  media_asset: MediaAssetAPI;
}

export interface StabilityAIPayload
{
  text: string;
  style_preset: string;
}

async function generateAiImage( stabilityAiPayload: StabilityAIPayload )
{
  try
  {
    const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_MEDIA_LIBRARY_GENERATE_AI_IMAGE_PATH );
    const response = await getToApi<BusinessGenerateAIImageAPI>( endPoint, stabilityAiPayload );
    if ( !!response )
    {
      return response.media_asset;
    }
    else
    {
      return Promise.reject( "Failed to get generate ai image for search_term: id=" + stabilityAiPayload.text );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to get generate ai image for search_term: id=" + stabilityAiPayload.text );
  }
}

async function updateBusinessClientUiFlagsJson( updatedClientUiFlags: ClientUiFlagsJsonAPI )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_UPDATE_CLIENT_UI_FLAGS_JSON_PATH );
  return postJsonToApi<UpdateClientUiFlagsJsonResponseAPI>( endPoint, {}, { client_ui_flags_json: updatedClientUiFlags } ).then( ( data ) =>
  {
    const clientUiFlagsJson = data.client_ui_flags_json;
    store.dispatch( setClientUiFlagsJson( clientUiFlagsJson ) );
    return data;
  } );
}

async function submitStyleQuizResponse( styleQuizResponse: {} )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_UPDATE_STYLE_QUIZ );
  return postJsonToApi<UpdateStyleQuizResponseAPI>( endPoint, {}, { style_quiz_response: styleQuizResponse } ).then( ( data ) =>
  {
    return data;
  } );
}

async function pregenerateSinglePlanSuggestion( date: string )
{
  return pregenerateMultiplePlanSuggestions( [date] );
}

async function pregenerateMultiplePlanSuggestions( dates: string[] )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_PREGENERATE_PLAN_SUGGESTIONS_PATH );
  return postJsonToApi<PregeneratePlanSuggestionsResponseAPI>( endPoint, {}, { dates } ).then( ( data ) =>
  {
    return data;
  } );
}

async function listPlanSuggestions( start_date: string, end_date: string )
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_PLAN_SUGGESTIONS_PATH );
  return getToApi<ListPlanSuggestionsResponseAPI>( endPoint, { start_date, end_date } ).then( ( data ) =>
  {
    return data;
  } );
}

async function extendPlan()
{
  const endPoint = buildCurrentBusinessMemberUrl( BUSINESS_EXTEND_PLAN_PATH );
  return postToApi<PlanStatusAPI>( endPoint ).then( ( data ) =>
  {
    return data;
  } );
}

async function storePostStrategyQuizResults( post_strategy_quiz_results: PostStrategyQuizResultsAPI )
{
  const endPoint = buildCurrentBusinessMemberUrl( STORE_POST_STRATEGY_QUIZ_RESULTS_PATH );
  const jsonBody = {
    post_strategy_quiz_results
  }
  return postJsonToApi<PostStrategyQuizResultsAPI>( endPoint, {}, jsonBody );
}

async function updateUnsubscribedFromNotifications( isUnsubscribedAllNotifications: boolean )
{
  const endPoint = buildCurrentBusinessMemberUrl( UPDATE_UNSUBSCRIBED_NOTIFICATIONS_PATH );

  const jsonBody = {
    unsubscribed_notifications: {
      unsubscribed_all_notifications: isUnsubscribedAllNotifications
    }
  }
  return postJsonToApi<RefreshBusinessAPI>( endPoint, {}, jsonBody ).then(
    ( data ) =>
    {
      updateBusinessInState( data );
      return data;
    }
  );
}

async function listSocialNetworkAccounts()
{
  const endPoint = buildCurrentBusinessMemberUrl( SOCIAL_NETWORK_ACCOUNTS_PATH );

  return await getToApi<SocialNetworkAccountsResponseAPI>( endPoint, {} );
}

async function listBusinesses( disabledOnly: boolean = false ): Promise<ListBusinessAPI>
{
  const endPoint = buildUrl( [LIST_BUSINESSES_PATH] );

  return await getToApi<ListBusinessAPI>( endPoint, { disabled_only: disabledOnly } );
}

async function switchBusiness( businessSlug: string ): Promise<SwitchBusinessAPI>
{
  const endPoint = buildUrl( [SWITCH_BUSINESSES_PATH] );

  return postJsonToApi<SwitchBusinessAPI>( endPoint, {}, { business_slug: businessSlug } ).then(
    ( data ) =>
    {
      eventTracker.logBusinessSwitched();
      return data;
    }
  );
}

async function getHasScheduledPostsForAccount( socialNetworkAccountId: string ): Promise<HasScheduledPostsAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( HAS_SCHEDULED_POSTS_FOR_ACCOUNT_PATH );
  return await getToApi<HasScheduledPostsAPI>( endPoint, { social_network_account_id: socialNetworkAccountId } );

}

async function refreshTiktokCreatorInfo( socialNetworkAccountId: string ): Promise<RefreshTiktokCreatorInfoAPI>
{
  const endPoint = buildCurrentBusinessMemberUrl( REFRESH_TIKTOK_CREATOR_INFO_PATH );
  return await getToApi<RefreshTiktokCreatorInfoAPI>( endPoint, { social_network_account_id: socialNetworkAccountId } );
}

