import React, { useEffect } from "react";
import { CreateAccountOrSignIn } from "./createAccountOrSignIn";
import { CREATE_ACCOUNT_SHOWN_VIA_SIGN_IN_LANDING_PAGE, ROUTE_SEO, ROUTES, SEARCH_PARAM_KEY_SOURCE, SEARCH_PARAM_VALUE_UNKNOWN } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isUserLoggedIn } from "../user/userSlice";
import { eventTracker } from "../../helpers/eventTracker";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";
import { currentUserBusinessId, eligibleToDefaultToPlan } from "../business/businessSlice";

export function SignInLandingPage()
{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const source = searchParams.get( SEARCH_PARAM_KEY_SOURCE ) || SEARCH_PARAM_VALUE_UNKNOWN;
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const eligibleForPlan = useSelector( ( state: RootState ) => eligibleToDefaultToPlan( state ) );
  const shouldDefaultToPlan = eligibleForPlan && apptimizeVariables.shouldDefaultToPlanTab();

  useEffect( () =>
  {
    if ( userIsLoggedIn && isCurrentBusinessSet )
    {
      if( shouldDefaultToPlan )
      {
        navigateWithSearchParams( ROUTES.PLAN );
      }
      else
      {
        navigateWithSearchParams( ROUTES.CHAT );
      }
    }
    else
    {
      if ( !userIsLoggedIn )
      {
        eventTracker.logInitialRunShown( source );
      }
    }
  }, [userIsLoggedIn, isCurrentBusinessSet] );

  function handleDialogOnClose()
  {
  }

  return <>
    <Helmet>
      <title>{ROUTE_SEO.SIGN_IN.title}</title>
      <meta name="description" content={ROUTE_SEO.SIGN_IN.description}/>
    </Helmet>
    <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_SIGN_IN_LANDING_PAGE}
                           shouldShowSignIn
                           onClose={handleDialogOnClose}/>
  </>;
}