import {
  VIDEO_OUTPUT_FORMAT_SLUGS,
  OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME,
  OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME,
  OUTPUT_FORMAT_BEST_FOR_YOUTUBE_SHORTS_ICON_NAME,
  OUTPUT_FORMAT_PROPERTIES_BY_SLUG
} from "../constants";
import { join } from "lodash";

export const outputFormatHelper = {
  doesClientSupport,
  getDisplayName,
  getIcon,
  getSmallSxProps,
  getLargeSxProps,
  getDisplayResolution,
  getBestForIconsLine1,
  getBestForIconsLine2,
  getSocialIcon,
  getSocialIconWidth,
  getPlaceholderPreviewAspect,
  isVideoOutputFormat,
}

function doesClientSupport( outputFormatSlug: string )
{
  return !!OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug];
}

function getDisplayName( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].displayName;
}

function getDisplayResolution( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].displayResolution;
}

function getBestForIconsLine1( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].bestForIconsLine1;
}

function getBestForIconsLine2( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].bestForIconsLine2;
}

function getIcon( outputFormatSlug: string )
{
  return join( ["/output_formats", OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].icon_file_name], "/" );
}

function getSocialIcon( icon_name: string )
{
  return "/social_networks/" + icon_name;
}

function getSocialIconWidth( icon_name: string )
{
  if ( icon_name === OUTPUT_FORMAT_BEST_FOR_ETSY_ICON_NAME )
  {
    return 10;
  }
  else if ( icon_name === OUTPUT_FORMAT_BEST_FOR_YOUTUBE_SHORTS_ICON_NAME ||
            icon_name === OUTPUT_FORMAT_BEST_FOR_SHOPIFY_ICON_NAME )
  {
    return 12;
  }
  return 14;
}

function getSmallSxProps( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].smallSxProps;
}

function getLargeSxProps( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].largeSxProps;
}

function getPlaceholderPreviewAspect( outputFormatSlug: string )
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[outputFormatSlug].previewPlaceholderAspect;
}

function isVideoOutputFormat(outputFormatSlug?: string)
{
  return !!outputFormatSlug && VIDEO_OUTPUT_FORMAT_SLUGS.includes(outputFormatSlug);
}