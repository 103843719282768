import { CommonTrackAPI, TrackAPI } from "./musicSlice";
import { Box, Stack, Typography } from "@mui/material";
import { PlayCircleOutlined, StopCircleOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import "./beatSyncIcon.scss"
import { musicItemHelper } from "./musicItemHelper";
import { ReadMoreTypography } from "../ui/readMoreTypography";

interface MusicItemProps
{
  track: CommonTrackAPI | TrackAPI;
  handleClicked: () => void;
  selected: boolean;
  loading: boolean;
  showStopIcon: boolean;
  showBottomText: boolean;
}

export function MusicItemView( props: MusicItemProps )
{
  const selected = props.selected;
  const loading = props.loading;

  function getIcon()
  {
    const iconStyle = { height: "45px", width: "45px" };
    if ( props.showStopIcon )
    {
      return <StopCircleOutlined sx={iconStyle}/>
    }
    else
    {
      return <PlayCircleOutlined sx={iconStyle}/>
    }
  }

  const border = selected ? "2px solid #3f51b5" : "2px solid transparent";
  const bottomText = musicItemHelper.getBottomText( props.track );
  return (
    <Box sx={{ display: "flex", m: 5, border, borderRadius: "5px", position: "relative", cursor: "pointer" }} onClick={props.handleClicked}>
      {getIcon()}

      <Stack sx={{ ml: 5 }} justifyContent={"center"}>
        <Stack direction="row" sx={{ width: "250px" }} gap={4} alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold" }} noWrap>{props.track.display_name}</Typography>
        </Stack>
        {!!bottomText && props.showBottomText && <Box sx={{ width: "250px", color: "#9D9D9D" }}><ReadMoreTypography moreText="Show more" lessText="Show less" cutoffLength={45}>{bottomText}</ReadMoreTypography></Box>}
      </Stack>

      {loading && <Box sx={{
        position: "absolute",
        background: "rgba(0,0,0,.15)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "5px",
        pointerEvents: "none"
      }}>
        <CircularProgress sx={{ position: "absolute", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}/>
      </Box>}
    </Box>
  );
}
