import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import React, { useContext } from "react";
import { SocialShareStatusBar } from "./socialShareStatusBar";
import { RefreshFacebookButton } from "../buttons/refreshFacebookButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SocialShareStatusBarTypography } from "./socialShareStatusBarTypography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { logToConsole } from "../utils/devLoggingHelper";
import { RefreshOAuthButton } from "../buttons/refreshOAuthButton";
import { ACCOUNT_REFRESH_BAR_SOURCE } from "../../helpers/trackingConstants";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { useDispatch } from "react-redux";

export interface SocialNetworkAccountRefreshBarProps
{
  socialNetworkAccount: SocialNetworkAccountAPI;
}

export function SocialNetworkAccountRefreshBar( props: SocialNetworkAccountRefreshBarProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const dispatch = useDispatch();

  function handleSupportedSocialNetworksRefreshComplete( succeeded?: boolean, switchedAccount?: boolean )
  {
    logToConsole( `${props.socialNetworkAccount.account_type} account refresh completed` );
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
    {
      socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
    } );

    if ( succeeded && switchedAccount )
    {
      dispatch( setAlertMessage( warningAlert( `You are now connected to a new ${props.socialNetworkAccount.account_type} account` ) ) )
    }
  }

  function getRefreshButtonForSupportedNetworks( socialNetworkAccount: SocialNetworkAccountAPI )
  {
    if ( socialNetworkAccountHelper.isTiktokAccount( socialNetworkAccount ) || socialNetworkAccountHelper.isYoutubeAccount( socialNetworkAccount ) )
    {
      return (<RefreshOAuthButton socialNetworkAccount={socialNetworkAccount}
                                  handleRefreshComplete={handleSupportedSocialNetworksRefreshComplete}
                                  source={ACCOUNT_REFRESH_BAR_SOURCE}
      />)
    }

    if ( socialNetworkAccountHelper.isFacebookPageAccount( socialNetworkAccount ) ||
         socialNetworkAccountHelper.isFacebookInstagramAccount( socialNetworkAccount ) )
    {
      return (<RefreshFacebookButton socialNetworkAccount={socialNetworkAccount}
                                     handleRefreshComplete={handleSupportedSocialNetworksRefreshComplete}
                                     source={ACCOUNT_REFRESH_BAR_SOURCE}
      />);
    }

    return undefined;
  }

  if ( !socialNetworkAccountHelper.needsRefresh( props.socialNetworkAccount ) )
  {
    return null;
  }

  const refreshButtonForSupportedSocialNetworks = getRefreshButtonForSupportedNetworks( props.socialNetworkAccount );
  if ( !!refreshButtonForSupportedSocialNetworks )
  {
    const tooltipMessage = <Stack>
      <Typography variant={"caption"} sx={{ fontWeight: 800 }}>Refresh Connection to Publish Posts</Typography>
      <Typography variant={"caption"}>Connections for your social profiles have expired. A post can't be published to a profile with an expired
                                      connection. Before refreshing, ensure popup blockers are turned off.</Typography>
    </Stack>
    const accountType = socialNetworkAccountHelper.getDisplayName( props.socialNetworkAccount )
    const content = <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <WarningAmberIcon sx={{ color: "white" }}/>
      <SocialShareStatusBarTypography>{accountType} connection expired </SocialShareStatusBarTypography>
      <Tooltip
        title={tooltipMessage}
        enterTouchDelay={50}>
        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
          <HelpOutlineIcon sx={{ color: "white", height: "15px", width: "15px" }}/>
        </Box>
      </Tooltip>
    </Stack>
    return (<SocialShareStatusBar content={content}
                                  rightButton={refreshButtonForSupportedSocialNetworks}/>);
  }

  const messageForUnsupportedNetworks = `Refreshing ${props.socialNetworkAccount.account_type} connection is not supported.`
  return (<SocialShareStatusBar content={messageForUnsupportedNetworks}/>);
}
