import { includes } from "lodash";
import { SOURCES_FROM_ALKAI_CRM } from "../constants";

export function isCrmSource( source: string | null )
{
  return includes( SOURCES_FROM_ALKAI_CRM, source );
}

export function isEmailCrmSource( source: string | null )
{
  const emailSources = [SOURCES_FROM_ALKAI_CRM.INTRO_EMAIL_CRM_SOURCE, SOURCES_FROM_ALKAI_CRM.DAY_ONE_NUDGE_EMAIL_CRM_SOURCE];
  return includes( emailSources, source );
}

export function isFacebookAdSource( source: string | null )
{
  const facebookAdSources = ["fb_memories_lookalikee_ripl",
                             "fb_coffee1_lookalike_ripl",
                             "fb_coffee2_nov_lookalike",
                             "fb_RE_lookalikee_ripl",
                             "fb_ap_dark_oct_lookalike",
                             "fb_generic_oct_lookalike",
                             "fb_sleeping_white_oct_lookalike",
                             "fb_time_oct_lookalke",
                             "fb_ap_dark_oct_lookalike",
                             "fb_ap_light_oct_lookalike",
                             "fb_coffee2_nov_broad",
                             "fb_coffee1_broad",
                             "fb_sleeping_white_nov_broad",
                             "fb_time_oct_broad",
                             "fb_broad_generic2_july",
                             "facebook_smallbiz_may",
                             "fb_logintest_dec"]
  return includes( facebookAdSources, source );
}