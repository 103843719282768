import * as React from "react";
import { ReactNode, useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { clone, join, merge, take } from "lodash";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";

export interface ReadMoreTypographyProps
{
  moreText?: string
  lessText?: string
  cutoffLength?: number;
  children: ReactNode;
  sx?: SxProps;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export function ReadMoreTypography( props: ReadMoreTypographyProps )
{
  const moreText = props.moreText || "read more";
  const lessText = props.lessText || "show less";

  const commonSxProps = props.sx || {};
  const sxPropsForReadMore = clone( commonSxProps );

  const text = props.children as string;
  const cutoffLength = props.cutoffLength || 100;
  const [isReadMore, setIsReadMore] = useState( true );
  const additionalStylingForReadMore = {
    cursor: "pointer",
    textDecoration: "underline"
  }

  const toggleReadMore = ( e ) =>
  {
    e.stopPropagation();
    setIsReadMore( !isReadMore );
  };

  return (
    <Box>
      <Typography sx={merge( commonSxProps )}
                  variant={props.variant}
                  display="inline">  {isReadMore ? join( take( text, cutoffLength ), "" ) : text}</Typography>
      {isReadMore && <Typography sx={merge( commonSxProps )}
                                 variant={props.variant}
                                 display="inline"
                                 onClick={toggleReadMore}>
        ...
      </Typography>}
      <Typography sx={merge( sxPropsForReadMore, {ml: 2}, additionalStylingForReadMore )}
                  variant={props.variant}
                  display="inline"
                  onClick={toggleReadMore}>
        {isReadMore ? moreText : lessText}
      </Typography>
    </Box>
  );
}