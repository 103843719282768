import { Link, Stack, Typography } from "@mui/material";
import { SocialNetworkAccountsSection } from "../userSettings/socialNetworkAccountsSection";
import { Helmet } from "react-helmet";
import { ALKAI_HOW_TO_CONNECT_SOCIAL_FAQ_URL, ROUTE_SEO, ROUTES } from "../constants";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import React from "react";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { browserUtils } from "../utils/browserUtils";
import AlertBanner from "../alert/alertBanner";

export function SocialMediaAccountsPage()
{
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const inInstagramOrFacebookBrowser = browserUtils.isFacebookBrowser() || browserUtils.isInstagramBrowser();

  async function handleBackClicked()
  {
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  return (
    <Stack spacing={5} sx={{ textAlign: "left", pt: 5 }} alignItems="start">
      <Helmet>
        <title>{ROUTE_SEO.SOCIAL_MEDIA_ACCOUNTS.title}</title>
        <meta name="description" content={ROUTE_SEO.SOCIAL_MEDIA_ACCOUNTS.description}/>
      </Helmet>
      <AlertBanner/>
      <Stack direction="row" sx={{ width: "100%", px: 5 }}>
        <IconButton onClick={handleBackClicked} sx={{ padding: "unset", position: "absolute" }}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography sx={{ textAlign: "center", flex: "1 1 0", width: "100%" }}>Social media accounts</Typography>
      </Stack>

      <Stack sx={{ width: "100%" }}>
        {inInstagramOrFacebookBrowser && <Stack direction="row" spacing={5} alignItems="center"
                                                sx={{ background: "#FF3002CC", width: "100%", p: 5, border: "3px solid #FF0707" }}>
          <WarningAmberOutlinedIcon/>
          <Typography sx={{ fontWeight: "bold" }}>Open in External Browser to Connect</Typography>
        </Stack>}

        <Stack direction="row" spacing={5} alignItems="center" sx={{ background: "#FFEC3D38", width: "100%", p: 5, border: "3px solid #FFEC3D" }}>
          <InfoOutlinedIcon/>
          <Typography>Need help? Read our <Link sx={{ color: "black" }} href={ALKAI_HOW_TO_CONNECT_SOCIAL_FAQ_URL} rel="noopener"
                                                         target="_blank">step-by-step guide</Link>
          </Typography>
        </Stack>
      </Stack>

      <SocialNetworkAccountsSection/>
    </Stack>
  );
}
