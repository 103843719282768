import React, { useContext } from 'react'
import { Button, Stack, Typography } from "@mui/material";
import { businessServices, SocialNetworkAccountType } from "../../services/business.services";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { ShareDestinationToggle } from "../editing/ShareDestinationToggle";
import { SvgIconComponent } from "@mui/icons-material"
import { eventTracker } from "../../helpers/eventTracker";
import { logToConsole } from "../utils/devLoggingHelper";
import { SETTINGS_PAGE_SOURCE, SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

interface ConnectOAuthSocialNetworkButtonProps
{
  accountType: SocialNetworkAccountType;
  source: SocialNetworkConnectionSourcePageType;
  iconType: SvgIconComponent | React.ComponentType;
  handleConnectionSucceeded?(accountType: SocialNetworkAccountType, switchedAccount?: boolean): void;
  handleConnectionFailed?(): void;
  handleConnectionStarted?(): void;
}

export function ConnectOAuthSocialNetworkButton( props: ConnectOAuthSocialNetworkButtonProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const fromSettings = props.source === SETTINGS_PAGE_SOURCE;

  const handleClick = ( event: React.MouseEvent ) =>
  {
    requestConnect();
  }

  async function handleConnectionSucceeded(switchedAccount: boolean)
  {
    const context = socialNetworkAccountsContext.socialNetworkAccounts;

    eventTracker.sendFirstSocialNetworkAccountConnectedIfNecessary( context, props.accountType, props.source );
    eventTracker.logSocialNetworkConnected( props.accountType, props.source );
    if ( !!socialNetworkAccountsContext )
    {
      const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
      socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
    }
    if ( !!props.handleConnectionSucceeded )
    {
      props.handleConnectionSucceeded(props.accountType, switchedAccount);
    }
  }

  async function handleConnectionFailed( errorReason?: string )
  {
    // TODO: add error message to the event
    eventTracker.logSocialNetworkConnectFailed( props.accountType, props.source, errorReason );
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  async function requestConnect()
  {
    logToConsole( `connect ${props.accountType} started` );
    eventTracker.logConnectSocialNetworkClicked( props.accountType, props.source );
    if ( !!props.handleConnectionStarted )
    {
      props.handleConnectionStarted();
    }
    const urlData = await businessServices.getSocialNetworkOauthConnectUrl( props.accountType );
    const oauthUrl = urlData.oauth_url
    businessServices.launchOauthViaWindow( oauthUrl, handleConnectionSucceeded, handleConnectionFailed );
  }

  return <>
    {!fromSettings && <ShareDestinationToggle icon={<props.iconType sx={{ width: 30, height: 30, mt: 3, color: "black" }}/>}
                                              label={`Connect a ${props.accountType} Account`}
                                              onClick={handleClick}
                                              checked={false}
    />
    }
    {
      fromSettings && <Stack
                     sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                     <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                       <props.iconType sx={{ width: 30, height: 30, color: "black" }}/>
                       <Typography>{props.accountType}</Typography>
                     </Stack>
                     <Button variant={"contained"} onClick={handleClick} sx={{ minWidth: "100px" }}>Connect</Button>
                   </Stack>
    }
  </>

}