import React from 'react'
import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import { RefreshSocialNetworkButton } from "./refreshSocialNetworkButton";
import { eventTracker } from "../../helpers/eventTracker";
import { SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

interface RefreshOAuthButtonProps
{
  handleRefreshStarted?(): void;
  handleRefreshComplete?( succeeded: boolean, switchedAccount?: boolean ): void;
  socialNetworkAccount: SocialNetworkAccountAPI;
  source:SocialNetworkConnectionSourcePageType;
  iconOnly?: boolean;
}

export function RefreshOAuthButton( props: RefreshOAuthButtonProps )
{
  const [showLoader, setShowLoader] = React.useState( false );

  async function handleClick()
  {
    eventTracker.logSocialNetworkAccountRefreshButtonClicked( props.socialNetworkAccount, props.source );
    if ( !!props.handleRefreshStarted )
    {
      props.handleRefreshStarted();
    }

    setShowLoader( true );
    const urlData = await businessServices.getSocialNetworkOauthRefreshUrl( props.socialNetworkAccount );
    const oauthUrl = urlData.oauth_url
    businessServices.launchOauthViaWindow( oauthUrl, handleRefreshSucceeded, handleRefreshFailed );
  }

  function handleRefreshSucceeded(switchedAccount?: boolean)
  {
    eventTracker.logSocialNetworkAccountRefreshSucceeded( props.socialNetworkAccount, props.source );
    setShowLoader( false );
    if ( !!props.handleRefreshComplete )
    {
      props.handleRefreshComplete( true, switchedAccount);
    }
  }

  function handleRefreshFailed( errorReason?: string )
  {
    eventTracker.logSocialNetworkAccountRefreshFailed( props.socialNetworkAccount, props.source, errorReason );
    setShowLoader( false );
    if ( !!props.handleRefreshComplete )
    {
      props.handleRefreshComplete( false );
    }
  }

  return (<RefreshSocialNetworkButton handleClick={handleClick} showLoader={showLoader} iconOnly={props.iconOnly}/>);
}
