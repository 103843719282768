import { Box, SxProps, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearAndStopAudioPlayerTrack } from "../ui/uiSlice";
import { VolumeOff } from "@mui/icons-material";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { merge } from "lodash";

interface NoMusicItemProps
{
  postIdea: PostIdeaDataAPI;
  selected: boolean;
  handleTrackSelected: () => void;
  iconSize?: number;
  sx?: SxProps;
}

export function NoMusicItem( props: NoMusicItemProps )
{
  const dispatch = useDispatch();
  const iconSize = props.iconSize || 56;
  const iconSizePx = `${iconSize}px`;

  function handleOnClick()
  {
    stopPlayingMusic();
    props.handleTrackSelected();
  }

  const stopPlayingMusic = () =>
  {
    dispatch( clearAndStopAudioPlayerTrack() );
  };

  const border = props.selected ? "2px solid #3f51b5" : "2px solid transparent";
  return (
    <Box sx={merge( { display: "flex", m: 5, border, borderRadius: "5px", alignItems: "center" }, props.sx )} onClick={handleOnClick}>
      <VolumeOff sx={{ height: iconSizePx, width: iconSizePx, color: "primary.main" }}/>
      <Typography variant={"h6"} sx={{ ml: 5, }}>No music</Typography>
    </Box>
  );
}
