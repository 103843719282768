import { NO_MUSIC_TRACK_ID, TRACK_TYPE_EPIDEMIC_SOUND, TRACK_TYPE_MELODIE, TRACK_TYPE_NO_MUSIC } from "../constants";
import { CommonTrackAPI, GlobalSettingsMusicDataAPI, MelodieTrackAPI, MusicSelection } from "./musicSlice";
import { isEmpty, join } from "lodash";
import { MelodieFeatureEnabledReplaceMusicTrackAPI } from "../../services/postIdeaServices";

export const musicItemHelper = {
  getBottomText,
  isEpidemicSoundTrack,
  isTrackIdSelected,
  getMusicTrackFromGlobalSettingsMusicData,
  getMusicTrackFromPendingSelection,
  getNoMusicTrack,
}

function getBottomText( track: CommonTrackAPI )
{
  if ( isMelodieTrack( track ) )
  {
    const moods = (track as MelodieTrackAPI)?.moods;
    return join( moods, ", " );
  }
  return null;
}

function isMelodieTrack( commonTrackAPI: CommonTrackAPI )
{
  return commonTrackAPI.type === TRACK_TYPE_MELODIE;
}

function isEpidemicSoundTrack( commonTrackAPI: CommonTrackAPI )
{
  return commonTrackAPI.type === TRACK_TYPE_EPIDEMIC_SOUND;
}

function isTrackIdSelected( musicItemTrackId: string, postIdeaTrackId: string, pendingSelection?: MusicSelection )
{
  if ( pendingSelection )
  {
    return pendingSelection?.id === musicItemTrackId;
  }
  else
  {
    return postIdeaTrackId === musicItemTrackId || (isEmpty( postIdeaTrackId ) && isEmpty( musicItemTrackId ));
  }
}

function getMusicTrackFromPendingSelection( pendingSelection: MusicSelection )
{
  return {
    ...pendingSelection,
    music_url: pendingSelection.url,
  } as MelodieFeatureEnabledReplaceMusicTrackAPI;
}

function getMusicTrackFromGlobalSettingsMusicData( currentMusicData: GlobalSettingsMusicDataAPI )
{
  return {
    ...currentMusicData.metadata,
    id: currentMusicData.id,
    type: currentMusicData.type,
    display_name: currentMusicData.metadata?.display_name || "Current track",
    url: currentMusicData.audio_url,
  } as CommonTrackAPI;
}

function getNoMusicTrack()
{
  return {
    url: "",
    id: NO_MUSIC_TRACK_ID,
    type: TRACK_TYPE_NO_MUSIC,
    startTimeInSeconds: 0,
  } as CommonTrackAPI;
}
