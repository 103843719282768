import { Stack, SxProps, Typography } from "@mui/material";
import { MelodieGenreGroupOrPurposeAPI } from "./musicSlice";
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';

interface MelodieGenreOrPurposeRowProps
{
  melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI;
  onClick: ( melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI ) => void;
  sx?: SxProps
}

export function MelodieGenreGroupOrPurposeRow( props: MelodieGenreOrPurposeRowProps )
{

  function handleOnClick()
  {
    props.onClick( props.melodieGenreGroupOrPurpose )
  }

  return (
    <Stack direction="row" onClick={handleOnClick} alignItems="center" justifyContent="flex-start" sx={props.sx}>
      <LibraryMusicOutlinedIcon sx={{fontSize: 40}}/>
      <Typography variant={"h6"} sx={{ ml: 5, }}>{props.melodieGenreGroupOrPurpose.name}</Typography>
    </Stack>
  );
}

