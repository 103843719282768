import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, Divider, Stack, Typography } from "@mui/material";
import { SelectLocalMediaButton } from "../buttons/selectLocalMediaButton";
import { MediaAssetData, mediaAssetServices } from "../../services/mediaAssetServices";
import { businessServices, MediaAssetAPI } from "../../services/business.services";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { filter, includes, isEmpty, map, remove, size, toLower } from "lodash";
import { clearMediaLibrary, getMediaAssets, getMediaLibraryNextPage } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import AddFromMediaLibraryGrid from "./addFromMediaLibraryGrid";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import heic2any from "heic2any";
import { mediaUtils } from "../../helpers/mediaUtils";
import { stringUtils } from "../utils/stringUtils";
import { SelectAnotherSourceButton } from "../buttons/selectAnotherSourceButton";
import { SelectMediaSourceDialog } from "./selectMediaSourceDialog";
import { MediaPickingMode, USER_UPLOAD_SOURCE_MEDIA_ASSET } from "../constants";
import { currentUserBusinessId } from "../business/businessSlice";
import { UNAUTHORIZED_ERROR } from "../../services/requestManager";
import { CuratedMediaSetAPI } from "../../services/curatedMediaSet.services";
import { CuratedMediaSetsCarousel } from "./curatedMediaSetsCarousel";
import { errorReporter } from "../error/errorReporter";

export interface LocalMediaUploadDialogProps
{
  id: string;
  postIdea?: PostIdeaDataAPI;
  keepMounted: boolean;
  handleChooseMediaAssets: ( mediaAssets: MediaAssetAPI[] ) => void;
  confirmationButtonText: string
  currentMediaUrls?: string[];
  logoPicking: boolean;
  maxMediaCount: number;
  maxMediaCountError: string;
  handleChangeTabs?: ( tabId: number ) => void;
  previewMediaInsteadOfSelect?: boolean;
  openedFromMediaLibraryTab?: boolean;
  mediaPickingMode?: MediaPickingMode;
  curatedMediaSets?: CuratedMediaSetAPI[];
  handleCuratedMediaSetClicked?: ( curateMediaSetSlug: string ) => void;
}

interface LocalMediaSelection
{
  fileName: string;
  localUrl: string;
  isVideo: boolean;
  fileData: File;
}

function LocalMediaUploadDialog( props: LocalMediaUploadDialogProps )
{
  const [loadingMedia, setLoadingMedia] = useState( false );
  const [preselectedMediaAssets, setPreselectedMediaAssets] = useState<MediaAssetAPI[]>( [] );
  const [uploading, setUploading] = useState( false );
  const dispatch = useDispatch();

  const mediaLibrary = useSelector( ( state: RootState ) => getMediaAssets( state ) );
  const nextPage = useSelector( ( state: RootState ) => getMediaLibraryNextPage( state ) );

  const loadedPagesRef = useRef<number[]>( [] );
  const [showSelectMediaSourceDialog, setShowSelectMediaSourceDialog] = useState( false );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      clearAndReloadMedia();
    }
  }, [isCurrentBusinessSet] );

  const clearAndReloadMedia = async () =>
  {
    resetLastPageRequestedAndClearMedia();
    return loadMediaForPage( 1 );
  }

  function resetLastPageRequestedAndClearMedia()
  {
    dispatch( clearMediaLibrary() );
    loadedPagesRef.current = [];
  }

  const onSelectAnotherSourceButtonClicked = () =>
  {
    setShowSelectMediaSourceDialog( true );
  }

  const handleMediaSelected = async ( selectFiles: FileList ) =>
  {
    if ( !!selectFiles && selectFiles.length > 0 )
    {
      setUploading( true );

      const filteredSelectFiles = filter( selectFiles, ( file ) =>
      {
        if ( props.logoPicking )
        {
          return mediaUtils.isImageFile( file );
        }
        return mediaUtils.isSupportedFileType( file )
      } );

      const acceptedFileTypes = props.logoPicking ? "images" : "images or videos";
      if ( size( filteredSelectFiles ) === 0 )
      {
        dispatch( setAlertMessage( errorAlert( `No supported files were selected. Please select only ${acceptedFileTypes}.` ) ) );
        setUploading( false );
        return;
      }

      if ( size( filteredSelectFiles ) < size( selectFiles ) )
      {
        dispatch(
          setAlertMessage(
            errorAlert( `Some of the selected files are not supported and were ignored. Please select only ${acceptedFileTypes}.` ) ) );
      }

      const convertedMediaFileSelections = await convertMediaFileSelections( filteredSelectFiles );
      const convertedSelections: unknown = filter( convertedMediaFileSelections,
        ( convertedMediaFileSelection ) => !isEmpty( convertedMediaFileSelection ) );

      const localMediaSelections = convertedSelections as LocalMediaSelection[];
      const fileAssetData = map( localMediaSelections, ( localMediaSelection: LocalMediaSelection ) =>
      {
        const mediaAsset: MediaAssetData = {
          fileName: localMediaSelection.fileName,
          file: localMediaSelection.fileData,
          fileType: mediaUtils.getMediaAssetFileType( localMediaSelection.fileName ),
          isLogo: props.logoPicking,
        }
        return mediaAsset;
      } );

      const batchSize = localMediaSelections.length;
      const mediaEventProps = map( localMediaSelections, ( selection: LocalMediaSelection ) =>
      {
        return {
          fileName: selection.fileName,
          fileSize: selection.fileData.size,
          fileType: mediaUtils.getMediaAssetFileType( selection.fileName ),
          batchSize: batchSize,
        }
      } );

      mediaEventProps.forEach( ( eventProps ) =>
      {
        if ( props.openedFromMediaLibraryTab )
        {
          eventTracker.logMediaLibraryUploadStarted( eventProps )
        }
        else if ( !!props.postIdea )
        {
          const postIdea = props.postIdea;
          eventTracker.logEditPostMediaUploadStarted( postIdea, eventProps );
        }
      } );

      let mediaAssets: (MediaAssetAPI[] | null) = null;

      try
      {
        mediaAssets = await mediaAssetServices.uploadAssets( fileAssetData, USER_UPLOAD_SOURCE_MEDIA_ASSET );

        mediaEventProps.forEach( ( eventProps ) =>
        {
          if ( props.openedFromMediaLibraryTab )
          {
            eventTracker.logMediaLibraryUploaded( eventProps );
          }
          else if ( !!props.postIdea )
          {
            const postIdea = props.postIdea;
            eventTracker.logEditPostMediaUploaded( postIdea, eventProps );
          }
        } );
        await refreshMedia( mediaAssets );
      }
      catch (error)
      {
        dispatch( setAlertMessage( errorAlert( "It looks like there was some trouble uploading media, please try again." ) ) );

        mediaEventProps.forEach( ( eventProps ) =>
        {
          const eventPropsWithError = {
            ...eventProps,
            error: errorReporter.extractMessageFromError( error ),
          };
          if ( props.openedFromMediaLibraryTab )
          {
            eventTracker.logMediaLibraryUploadFailed( eventPropsWithError );
          }
          else if ( !!props.postIdea )
          {
            const postIdea = props.postIdea;

            eventTracker.logEditPostMediaUploadFailed( postIdea, eventPropsWithError );
          }
        } );

        if ( error !== UNAUTHORIZED_ERROR )
        {
          await refreshMedia( mediaAssets );
        }
      }

      setUploading( false );
    }
  };

  async function refreshMedia( mediaAssets: MediaAssetAPI[] | null )
  {
    await clearAndReloadMedia();

    if ( !!mediaAssets && !props.openedFromMediaLibraryTab )
    {
      setPreselectedMediaAssets( mediaAssets );
    }
  }

  function handleLocalMediaUploadClicked()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      eventTracker.logMediaLibraryUploadClicked();
    }
    else if ( !!props.postIdea )
    {
      eventTracker.logEditPostMediaUploadClicked( props.postIdea );
    }
  }

  const loadMediaForNextPage = async () =>
  {
    if ( !wasPageAlreadyRequested( nextPage ) )
    {
      return loadMediaForPage( nextPage );
    }
  }

  const loadMediaForPage = async ( pageToLoad: number ) =>
  {
    if ( pageToLoad !== undefined )
    {
      setLoadingMedia( true );
      loadedPagesRef.current.push( pageToLoad );
      businessServices.fetchMediaLibrary( { page: pageToLoad, logo_picking: props.logoPicking } ).finally( () =>
      {
        remove( loadedPagesRef.current, ( n ) => n === pageToLoad );
        setLoadingMedia( false );
      } );
    }
  }

  const wasPageAlreadyRequested = ( pageToLoad: number ) =>
  {
    return includes( loadedPagesRef.current, pageToLoad );
  }

  const hasMore = () =>
  {
    return !!nextPage;
  }

  const handleDeleteMedia = async ( mediaAsset: MediaAssetAPI ) =>
  {
    await businessServices.disableMediaAssetInLibrary( mediaAsset.id );
    await clearAndReloadMedia();
  }

  const getAcceptedFileTypes = () =>
  {
    if ( props.logoPicking )
    {
      return "image/*,.heic";
    }
    return "image/*,.mp4,.mov,.heic";
  }

  function handleSourceSelected( tabId: number )
  {
    handleSelectMediaSourceDialogClose();
    if ( props.handleChangeTabs )
    {
      props.handleChangeTabs( tabId );
    }
  }

  function handleSelectMediaSourceDialogClose()
  {
    setShowSelectMediaSourceDialog( false );
  }

  function getSubheadText()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      return "Manage media in your library";
    }
    return "Select media from library";
  }

  return (
    <Box sx={{
      height: "100%",
      overflow: "auto",
      textAlign: "center",
    }}>

      <Stack direction="row" justifyContent="center" spacing={4}>
        <SelectLocalMediaButton
          multiple={true}
          accept={getAcceptedFileTypes()}
          onMediaSelected={handleMediaSelected}
          handleClick={handleLocalMediaUploadClicked}
        />

        {!props.logoPicking && <SelectAnotherSourceButton
          onClick={onSelectAnotherSourceButtonClicked}/>}
      </Stack>

      <Divider sx={{ my: 5, maxWidth: "350px", mx: "auto" }}/>

      {props.curatedMediaSets && size( props.curatedMediaSets ) > 0 && !props.openedFromMediaLibraryTab && props.handleCuratedMediaSetClicked
       && <CuratedMediaSetsCarousel curatedMediaSets={props.curatedMediaSets} handleCuratedMediaSetClicked={props.handleCuratedMediaSetClicked}/>}
      <Typography variant="subtitle1" paddingBottom={5}>{getSubheadText()}</Typography>
      {size( mediaLibrary ) === 0 && !loadingMedia && <Typography variant={"subtitle1"}>Your media will appear here</Typography>}

      <AddFromMediaLibraryGrid
        mediaAssets={mediaLibrary}
        currentMediaUrls={props.currentMediaUrls}
        preselectedMediaAssets={preselectedMediaAssets}
        uploading={uploading}
        loadMedia={loadMediaForNextPage}
        hasMore={hasMore}
        buttonText={props.confirmationButtonText}
        handleChooseMediaAssets={props.handleChooseMediaAssets}
        handleDeleteMedia={props.logoPicking ? undefined : handleDeleteMedia}
        maxMediaCount={props.maxMediaCount}
        maxMediaCountError={props.maxMediaCountError}
        logoPicking={props.logoPicking}
        mediaPickingMode={props.mediaPickingMode}
        previewMediaInsteadOfSelect={props.previewMediaInsteadOfSelect}
      />
      {!props.logoPicking && <SelectMediaSourceDialog handleSourceSelected={handleSourceSelected}
                                                      open={showSelectMediaSourceDialog}
                                                      handleClose={handleSelectMediaSourceDialogClose}/>}
    </Box>
  );
}

async function convertMediaFileSelections( inputFiles: File[] )
{
  return await Promise.all( map( inputFiles, async ( file ) =>
  {
    const originalFileName = file.name;
    const lowercasedFileName = toLower( originalFileName );
    let isHeic = includes( lowercasedFileName, ".heic" );
    const isVideo = mediaUtils.isVideoFile( file );

    if ( !isHeic && !isVideo )
    {
      await mediaUtils.isHeicFile( file ).then( ( isHeicMislabeled ) =>
      {
        isHeic = isHeicMislabeled;
      } );
    }

    if ( isHeic )
    {
      eventTracker.logHeicToJpegConversionStarted( lowercasedFileName );
      try
      {
        const conversionResult = await heic2any( { blob: file, toType: "image/jpeg", quality: 0.7 } );
        eventTracker.logHeicToJpegConversionCompleted( lowercasedFileName );
        const fileNameAsJpg = stringUtils.replaceFileExtension( lowercasedFileName, ".jpg" );
        return {
          fileName: fileNameAsJpg,
          localUrl: URL.createObjectURL( conversionResult as Blob ),
          isVideo: isVideo,
          fileData: conversionResult as File,
        }
      }
      catch (error: any)
      {
        eventTracker.logHeicToJpegConversionFailed( originalFileName, error.message )
        return {};
      }
    }

    return {
      fileName: originalFileName,
      localUrl: URL.createObjectURL( file ),
      isVideo: isVideo,
      fileData: file,
    }
  } ) );
}

export default LocalMediaUploadDialog;
