import { getToApi, makeAuthenticatedRequest, postJsonToApi } from "./requestManager";
import { ENV_CONFIG } from "../constants";
import { MelodieEntityType, MelodieGenreGroupsAndPurposesAPI, MelodieTracksAPI, MusicAPI, setTracks } from "../features/music/musicSlice";
import { store } from "../app/store";
import { clearProgressState, setProgressState } from "../features/ui/uiSlice";
import { RequestPresignedUploadAPIResponse, s3UploadServices } from "./s3Upload.services";
import { businessServices } from "./business.services";
import { concat } from "lodash";

const REQUEST_PRESIGNED_UPLOAD_ENDPOINT = "request_presigned_upload";
const MUSIC_BASE_PATH = "uploaded_musics";

export const musicServices = {
  getEpidemicSoundCollection,
  getEpidemicSoundTrack,
  getMelodieGenresGroupsAndPurposes,
  getMelodieTracks,
  uploadMusic,
}

export interface EpidemicSoundTrackDownloadAPI
{
  epidemic_id: string;
  url: string;
  expires: string;
  start_time_in_seconds: number;
}

export interface MusicFileData
{
  file: File;
}

export interface UploadedMusicData
{
  display_name: string;
  artist_name?: string;
  duration_in_ms: number;
  s3_url: string;
}

async function getMelodieGenresGroupsAndPurposes(): Promise<MelodieGenreGroupsAndPurposesAPI>
{

  const endPoint = "music/melodie_genre_groups_and_purposes";

  return getToApi<MelodieGenreGroupsAndPurposesAPI>( endPoint, {} );
}

async function getMelodieTracks( type: MelodieEntityType, name: string, page?: number ): Promise<MelodieTracksAPI>
{
  const endPoint = "music/melodie_tracks";

  const params = {
    name,
    type,
    page
  }

  return postJsonToApi<MelodieTracksAPI>( endPoint, {}, params );
}

async function getEpidemicSoundCollection(): Promise<MusicAPI>
{
  const baseUrl = ENV_CONFIG.baseUrl;
  const endPoint = "music/epidemic_sound_collection";

  return makeAuthenticatedRequest<MusicAPI>( { baseUrl, endPoint, methodType: "GET" } ).then(
    ( data ) =>
    {
      store.dispatch( setTracks( data ) );
      return data;
    }
  );
}

async function getEpidemicSoundTrack( epidemic_id: string ): Promise<EpidemicSoundTrackDownloadAPI>
{
  const endPoint = "music/epidemic_sound_track_download?";

  return getToApi<EpidemicSoundTrackDownloadAPI>( endPoint, { epidemic_track_id: epidemic_id } ).then(
    ( data ) =>
    {
      return data;
    }
  );
}

async function uploadMusic( musicFile: MusicFileData ): Promise<UploadedMusicData>
{
  store.dispatch( clearProgressState() );
  store.dispatch( setProgressState( { message: "Uploading music..." } ) );
  const endPointUrl = buildUrl( [REQUEST_PRESIGNED_UPLOAD_ENDPOINT] );

  const fileName = musicFile.file.name;

  const presignParams = {
    file_name: fileName
  }
  const presignedUpload: RequestPresignedUploadAPIResponse = await postJsonToApi( endPointUrl, {}, presignParams );
  await s3UploadServices.uploadFileToS3( presignedUpload.url, presignedUpload.fields, musicFile.file );

  const artistName = "Unknown"; // change this once we have file metadata
  const durationInMs = 0; // change this once we have file metadata
  return await businessServices.addMusicToLibrary( presignedUpload.s3_direct_url, fileName, artistName, durationInMs );
}

function buildUrl( pathPieces: string[] )
{
  return concat( [MUSIC_BASE_PATH], pathPieces ).join( "/" );
}