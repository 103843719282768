import { Link, Stack, Typography } from "@mui/material";
import { ROUTES } from "../constants";
import * as React from "react";
import ProgressOverlay from "../loadingIndicator/progressOverlay";

export interface ExternalConnectionInfoSectionProps
{
  circleSize?: number;
  loadingMessage: string;
  isConnectingFacebookOrInstagram: boolean;
  isConnectingOAuthAccount: boolean;
  handleCancelOAuthConnect?: () => void;
}

export function ExternalConnectionInfoSection( props: ExternalConnectionInfoSectionProps )
{
  function getBelowLoadingText()
  {
    if ( props.isConnectingFacebookOrInstagram )
    {
      return (<Stack sx={{ pt: 5 }} spacing={4}>
        <Typography variant={"caption"} sx={{ color: "black", textAlign: "center" }}>We’re opening a new browser window to connect your account. It
                                                                                     may open behind this window or be blocked by pop-up blockers.
                                                                                     Ensure pop-ups are allowed. If you have trouble connecting,
                                                                                     locate and close the Facebook browser to cancel the
                                                                                     connection and try again </Typography>
        <Typography variant={"caption"} sx={{ color: "black", textAlign: "center" }}>You’ll be connecting through Ripl, Alkai's parent
                                                                                     company.</Typography>
      </Stack>);
    }
    else if ( props.isConnectingOAuthAccount )
    {
      return (<Stack sx={{ pt: 5 }} spacing={4}>
        <Typography variant={"caption"} sx={{ color: "black", textAlign: "center" }}>We’re opening a new browser tab in this window to connect your
                                                                                     account.</Typography>
        <Typography variant={"caption"} sx={{ color: "black", textAlign: "center" }}>Once connected, the new browser tab should close and this
                                                                                     message should disappear. {getCancelText()} If problems persist,
                                                                                     reach out to&nbsp;
          <Link sx={{
            fontWeight: "bold",
            fontSize: "14px",
            cursor: "pointer"
          }} href={ROUTES.NEW_SUPPORT_TICKET}>customer support</Link>
        </Typography>
      </Stack>);
    }
    else
    {
      return null;
    }
  }

  function getCancelText()
  {
    if ( !!props.handleCancelOAuthConnect )
    {
      return <>
        If you have trouble connecting, click&nbsp;
        <Link sx={{ fontWeight: "bold", fontSize: "14px", cursor: "pointer" }} onClick={props.handleCancelOAuthConnect}>here</Link>
        &nbsp;to cancel the connection and try again.
      </>;
    }
    return <>
      If you have trouble connecting, please click above this banner
      to cancel the connection and try again.
    </>;
  }

  return <ProgressOverlay hideBackDrop={true} wheelColor="primary.main" circleSize={props.circleSize ? props.circleSize : 60}
                          message={props.loadingMessage}
                          belowProgressComponent={getBelowLoadingText()} sx={{
    width: "100%",
    height: "100%",
    position: "absolute",
    background: "white",
    top: 0,
    left: 0
  }}/>
}