import { isError, isObject, isString } from "lodash";
import * as Sentry from "@sentry/browser";
import { ENABLE_ERROR_REPORTING } from "../../constants";
import { getIsInternalUser, getUserSlug, isGuestUser } from "../user/userSlice";
import { store } from "../../app/store";
import { currentUserBusinessId } from "../business/businessSlice";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { ScopeContext } from "@sentry/types/types/scope";

export const errorReporter = {
  reportErrorToSentry,
  extractMessageFromError,
}

function reportErrorToSentry( error, extra?: string | object )
{
  if ( ENABLE_ERROR_REPORTING )
  {
    try
    {
      const state = store.getState();
      let sentryContext: Partial<ScopeContext> = {
        user: {
          id: getUserSlug( state ),
          is_internal_user: getIsInternalUser( state ),
          business_id: currentUserBusinessId( state ),
          is_guest: isGuestUser( state )
        },

      }

      if ( isString( extra ) )
      {
        sentryContext.extra = { message: extra };
      }
      else if ( isObject( extra ) )
      {
        sentryContext = {
          ...sentryContext,
          extra: {
            ...extra
          }
        }
      }

      if ( isErrorReportableToSentry( error ) )
      {
        Sentry.captureException( error, sentryContext );
      }
      else
      {
        const message = extractMessageFromError( error );
        const wrappedError = new Error( message );
        Sentry.captureException( wrappedError, sentryContext );
      }
    }
    catch (ex)
    {
      logToConsoleError( "Exception encountered while reporting error to Sentry", ex );
    }
  }
}

function isErrorReportableToSentry( possibleError: any )
{
  if ( isError( possibleError ) )
  {
    return true;
  }
  else if ( typeof possibleError === 'object' )
  {
    return isError( possibleError.originalError ) || isError( possibleError.error );
  }
  return false;
}

function extractMessageFromError( error: any )
{
  if ( typeof error === 'string' )
  {
    return error;
  }
  else if ( isError( error ) )
  {
    return error.message;
  }
  else if ( typeof error === 'object' )
  {
    return JSON.stringify( error );
  }
  return "Error of " + typeof error + " type encountered";
}
